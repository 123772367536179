import ModalFooter from '@Components/ModalFooter';
import PageSkeletons from '@Components/PageSkeletons';
import SearchableSelect from '@Components/SearchableSelect';
import Status from '@Enums/Status';
import { useMacAdressRules } from '@Hooks/useMacAdressRules';
import { getAllAvailableBrands } from '@Store/Brand/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getAllStoresOfBrands, resetAllStores } from '@Store/Store/action';
import { getUserStores } from '@Store/User/action';
import { AllBeacon } from '@Types/Beacon';
import { UserEmails } from '@Types/User';
import { Form, Input, Select } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onFinish?: (values: any) => void;
  beaconData?: AllBeacon;
  allEditForm?: boolean;
};
export default function BeaconForm({
  onFinish,
  beaconData,
  allEditForm,
}: Props) {
  const { t } = useTranslation();
  const macAdressRules = useMacAdressRules();
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const brands = useAppSelector(s => s.Brand.allAvailableBrands);
  const stores = useAppSelector(s => s.Store.allStores);
  const userStoresAndEmails = useAppSelector(s => s.User.userStoresAndEmails);

  const brandId = useWatch('BrandId', form);
  const storeIds = useWatch('StoreIds', form);

  useEffect(() => {
    dispatch(getAllAvailableBrands());
  }, []);

  useEffect(() => {
    if (brandId) {
      dispatch(getAllStoresOfBrands([brandId]));
      dispatch(getUserStores(brandId));
    }
  }, [brandId]);

  useEffect(() => {
    if (beaconData?.BrandId) {
      dispatch(getAllStoresOfBrands([beaconData.BrandId]));
    }
  }, [beaconData]);

  const userOptions = useMemo(() => {
    if (!userStoresAndEmails?.data || !Array.isArray(storeIds)) {
      return [];
    }

    const users = userStoresAndEmails?.data
      .filter(store => storeIds.includes(store.StoreId))
      .map(store => store.Users)
      .flat();

    // make sure no duplicated users exist
    // userId -> user
    const userMap: Record<string, UserEmails> = {};

    for (const user of users) {
      userMap[user.UserId] = user;
    }

    return Object.values(userMap).map(user => ({
      label: user.UserEmail,
      value: user.UserId,
    }));
  }, [userStoresAndEmails?.data, storeIds]);

  const handleBrandChange = () => {
    form.resetFields(['StoreIds']);
    dispatch(resetAllStores());
  };

  if (stores.status === 'Pending' && brands.status === 'Pending')
    return <PageSkeletons />;

  return (
    <Form
      form={form}
      name="beacon-form"
      onFinish={onFinish}
      initialValues={
        beaconData
          ? {
              ...beaconData,
              StoreIds: beaconData.StoreId ? [beaconData.StoreId] : undefined,
            }
          : undefined
      }
    >
      <Form.Item label={t('name')} name="Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label={t('surname')}
        name="Surname"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      {!allEditForm && (
        <Form.Item
          label={t('brand')}
          rules={[{ required: true }]}
          name={'BrandId'}
          hidden={!!beaconData}
        >
          <SearchableSelect
            placeholder={t('selectBrand')}
            allowClear
            showSearch
            onChange={handleBrandChange}
          >
            {brands.data.map(brand => (
              <Select.Option key={brand.Id} value={brand.Id} label={brand.Name}>
                {brand.Name}
              </Select.Option>
            ))}
          </SearchableSelect>
        </Form.Item>
      )}

      {!allEditForm && (
        <Form.Item
          label={t('store')}
          rules={[{ required: true }]}
          name={'StoreIds'}
          hidden={!!beaconData}
        >
          <SearchableSelect
            placeholder={t('selectStore')}
            mode="multiple"
            allowClear
            maxTagCount={1}
            showSearch
          >
            {stores.data.map(store => (
              <Select.Option key={store.Id} value={store.Id} label={store.Name}>
                {store.Name}
              </Select.Option>
            ))}
          </SearchableSelect>
        </Form.Item>
      )}

      {((Array.isArray(storeIds) && storeIds.length > 0) ||
        beaconData?.UserMail) && (
        <Form.Item label={t('user')} name="UserId" rules={[{ required: true }]}>
          <SearchableSelect
            options={userOptions}
            loading={userStoresAndEmails.status === Status.pending}
          />
        </Form.Item>
      )}

      {!beaconData && (
        <>
          <Form.Item
            label={'Bt Mac Address'}
            name="BtMacAddress"
            rules={[...macAdressRules, { required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t('externalId')} name="ExternalId">
            <Input />
          </Form.Item>
        </>
      )}

      <ModalFooter formKey="beacon-form" allEditForm={allEditForm} />
    </Form>
  );
}
