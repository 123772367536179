import { Badge, BadgeProps } from 'antd';
import './style.scss';
import { Tooltip } from '@Components/Tooltip';

export type CardInfoProps = Omit<BadgeProps, 'title'> & {
  title?: React.ReactNode;
  icon: React.ReactElement;
  showOnHoverOnly?: boolean;
};

function CardInfo({ title, icon, ...restProps }: CardInfoProps) {
  return (
    <Badge className="card-info-wrapper" {...restProps}>
      {title ? (
        <Tooltip placement="left" title={title}>
          <div className="info-wrapper">{icon}</div>
        </Tooltip>
      ) : (
        <div className="info-wrapper">{icon}</div>
      )}
    </Badge>
  );
}

export default CardInfo;
