import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import Status from '@Enums/Status';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getStoreCheckins } from '@Store/Store/action';
import { FilterOption } from '@Types/Pagination';
import { CheckInOut } from '@Types/Store';
import { Card, Pagination, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { CustomSortByFilter } from './SortByFilter';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import CardGridWrapper from '@Components/CardGridWrapper';
import DataDisplayTable from '@Components/DataDisplayTable';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { convertApiDate, toCommas } from '@Utils/index';
import GoBackButton from '@Components/GoBackButton';
import { useUserSelectList } from '@Hooks/api';
import { Tooltip } from '@Components/Tooltip';

export function StoreCheckinsPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const checkins = useAppSelector(state => state.Store.checkins);
  const allUsers = useUserSelectList();

  const filterOptions: FilterOption[] = [
    {
      field: 'StoreId',
      label: t('store') + ' ID',
      type: FilterType.NUMBER,
    },
    {
      field: 'UserId',
      label: t('users'),
      type: FilterType.TEXT_ARRAY_SELECT,
      loading: allUsers?.status === Status.pending,
      options: allUsers?.data?.map(u => ({
        label: u.Text,
        value: u.Value,
      })),
    },
  ];

  const pageConfigResult = useUserPageConfig(
    'store-checkins',
    {
      orderBy: 'asc',
      sortKey: 'StoreId',
    },
    { fields: filterOptions.map(o => o.field) }
  );

  const { pagination } = usePaginatedQuery({
    status: checkins.status,
    filterOptions,
    pageConfigResult,
    queryFunction: p => dispatch(getStoreCheckins(p)),
  });

  const tableColumns: ColumnsType<CheckInOut> = [
    {
      title: t('entityIds'),
      dataIndex: 'entities',
      render: (_, item) => (
        <HierarchyItems
          brandId={item?.BrandId}
          storeId={item.StoreId}
          brand={item?.BrandName}
          store={item.StoreName}
          textWidth="20ch"
        />
      ),
    },
    {
      title: t('username'),
      dataIndex: 'UserName',
    },
    {
      title: 'Email',
      dataIndex: 'UserMail',
    },
    {
      title: t('start'),
      dataIndex: 'start-checkin',
      render: (_, item) => (
        <Checkin date={item.StartTime} distance={item.StartDistMeter} />
      ),
    },
    {
      title: t('end'),
      dataIndex: 'end-checkin',
      render: (_, item) => (
        <Checkin date={item.EndTime} distance={item.EndDistMeter} />
      ),
    },
  ];

  useSetHeaderTitle(t('services'));

  return (
    <Card className="tickets-page">
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <GoBackButton />
          <CustomSortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
        </div>

        <CardGridWrapper
          status={checkins.status}
          noData={checkins.data?.Data?.length === 0}
          cards={
            <DataDisplayTable
              columns={tableColumns}
              dataSource={checkins.data?.Data}
            />
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={checkins.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={checkins.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}

type CheckinProps = {
  date?: string;
  distance?: number;
};

function Checkin({ date, distance }: CheckinProps) {
  const { t } = useTranslation();

  if (typeof date === 'string' && typeof distance === 'number') {
    return (
      <Tooltip title={t('serviceDistanceInfo')}>
        <p>{`${convertApiDate(date, true)} - ${toCommas(distance)} ${t(
          'meters'
        )}`}</p>
      </Tooltip>
    );
  }

  return <Tag color="volcano">{t('unfinished')}</Tag>;
}
