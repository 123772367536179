import { useForm } from 'antd/lib/form/Form';
import { Button, Checkbox, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { CopyOutlined, LockOutlined } from '@ant-design/icons';
import ModalFooter from '@Components/ModalFooter';
import { WifiConfigiration } from '@Types/WifiConfigiration';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createMessage } from '@Utils/index';
import PageSkeletons from '@Components/PageSkeletons';
import { Popconfirm } from '@Components/Popconfirm';
import { Tooltip } from '@Components/Tooltip';

type Props = {
  wifiConfiguration?: WifiConfigiration;
  status?: string;
  staticConf?: boolean;
  closeFooter?: boolean;
  onSubmit?: (wifiConf: WifiConfigiration) => void;
  name?: string;
  key?: number;
  customFooter?: boolean;
  handleDelete?: (confId: number) => void;
  deleteButtonShow?: boolean;
  hideGoBackButton?: boolean;
};

const WifiConfForm = ({
  wifiConfiguration,
  status,
  closeFooter,
  onSubmit,
  name,
  customFooter,
  handleDelete,
  deleteButtonShow,
  hideGoBackButton = false,
}: Props) => {
  const [initialValues, setInitialValues] = useState<WifiConfigiration>();

  const [form] = useForm();
  const { t } = useTranslation();

  const formValues = Form.useWatch([], form);

  const submitRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (wifiConfiguration) {
      form.setFieldsValue(wifiConfiguration);
      setInitialValues(wifiConfiguration);
    } else {
      form.setFieldsValue({ SSID: null });
      form.setFieldsValue({ Password: null });
      form.setFieldsValue({ Hidden: false });
    }
  }, [wifiConfiguration]);

  const handleSubmit = (wifiConf: WifiConfigiration) => {
    if (wifiConfiguration?.Id) {
      wifiConf.Id = wifiConfiguration.Id;
    }
    if (onSubmit) {
      onSubmit(wifiConf);
      form.resetFields();
    }
  };

  const handleCopy = (copyText: string | undefined) => {
    if (copyText) {
      navigator.clipboard.writeText(copyText);
      createMessage('success', t('copiedToClipboard'), copyText);
    }
  };

  function SaveButton() {
    return hasSpacesOnSsidEnding ? (
      <Popconfirm
        onConfirm={() =>
          submitRef.current?.form?.requestSubmit &&
          submitRef.current?.form?.requestSubmit()
        }
        title={t('hasSpacesOnSsidEndingWarning')}
      >
        <Button
          ref={submitRef}
          className="form-submit-button"
          type="primary"
          htmlType="submit"
        >
          {t('save')}
        </Button>
      </Popconfirm>
    ) : (
      <Button className="form-submit-button" type="primary" htmlType="submit">
        {t('save')}
      </Button>
    );
  }

  const showSaveButton = useMemo(() => {
    if (!wifiConfiguration || !initialValues) {
      return true;
    }

    for (const key of Object.keys(formValues)) {
      if (formValues[key] !== initialValues[key as keyof WifiConfigiration]) {
        return true;
      }
    }

    return false;
  }, [wifiConfiguration, initialValues, formValues]);

  const hasSpacesOnSsidEnding = useMemo(() => {
    const ssid = formValues?.SSID as string | undefined | null;

    if (typeof ssid === 'string') {
      return ssid.length !== ssid.trimEnd().length;
    }

    return false;
  }, [formValues?.SSID]);

  if (status === 'Pending') return <PageSkeletons />;

  return (
    <Form
      form={form}
      name={name ? name : 'wifi-conf-form'}
      onFinish={handleSubmit}
      className="wifi-conf-form-container"
      initialValues={wifiConfiguration}
      labelCol={{ span: 6 }}
      labelWrap
      labelAlign="left"
      wrapperCol={{ span: 24 }}
    >
      <div>
        {wifiConfiguration?.Name && (
          <Form.Item label={t('name')}>
            {wifiConfiguration?.Name ?? '-'}
          </Form.Item>
        )}
        <Form.Item label={'SSID'} name="SSID" rules={[{ required: true }]}>
          <Input
            id={wifiConfiguration?.SSID}
            addonAfter={
              wifiConfiguration ? (
                <Tooltip title="Kopyala">
                  <CopyOutlined
                    onClick={() => handleCopy(wifiConfiguration?.SSID)}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              ) : (
                <></>
              )
            }
          />
        </Form.Item>
        <Form.Item label={t('password')} name="Password">
          <Input.Password
            prefix={<LockOutlined className="wifi-form-item-icon" />}
            type="password"
            placeholder="Password"
            autoComplete="new-password"
            addonAfter={
              wifiConfiguration ? (
                <Tooltip title="Kopyala">
                  <CopyOutlined
                    onClick={() => handleCopy(wifiConfiguration?.Password)}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              ) : (
                <></>
              )
            }
          />
        </Form.Item>
        <Form.Item label={'Hidden'} name="Hidden" valuePropName="checked">
          <Checkbox />
        </Form.Item>

        <Form.Item name="KeyManagement" label="key-mgm">
          <Input
            maxLength={16}
            addonAfter={
              wifiConfiguration ? (
                <Tooltip title="Kopyala">
                  <CopyOutlined
                    onClick={() => handleCopy(wifiConfiguration?.KeyManagement)}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              ) : (
                <></>
              )
            }
          />
        </Form.Item>
      </div>

      {!closeFooter && (
        <ModalFooter
          formKey="wifi-conf-form"
          hideGoBackButton={hideGoBackButton}
          hideSendButton
          sendButtonText={t('save')}
        >
          <SaveButton />
        </ModalFooter>
      )}
      {customFooter && (
        <div className="modal-footer-container">
          {showSaveButton && <SaveButton />}
          {deleteButtonShow && (
            <Popconfirm
              onConfirm={() =>
                wifiConfiguration?.Id &&
                handleDelete &&
                handleDelete(wifiConfiguration.Id)
              }
            >
              <Button type="dashed" danger>
                {t('delete')}
              </Button>
            </Popconfirm>
          )}
        </div>
      )}
    </Form>
  );
};

export default WifiConfForm;
