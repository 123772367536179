import { Tooltip } from '@Components/Tooltip';
import Title, { TitleProps } from 'antd/lib/typography/Title';

interface IProps extends TitleProps {
  text?: string;
}

export function FirstItemText({ text, ...restProps }: IProps) {
  return (
    <Tooltip title={text}>
      <Title
        level={5}
        className="first-item-text"
        ellipsis
        style={{
          zIndex: 2,
        }}
        {...restProps}
      >
        {text}
      </Title>
    </Tooltip>
  );
}
