import { DataDisplay, ToggleDisplay } from '@Hooks/useDataDisplay';
import { Button } from 'antd';
import { AppstoreOutlined, TableOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@Components/Tooltip';

interface Props {
  currentDisplay: DataDisplay;
  onToggle: ToggleDisplay;
}

export default function DataDisplayToggler({
  currentDisplay,
  onToggle,
}: Props) {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t(
        `dataDisplayToggler.${currentDisplay === 'card' ? 'card' : 'table'}`
      )}
    >
      <Button
        onClick={onToggle}
        icon={
          currentDisplay === 'card' ? <AppstoreOutlined /> : <TableOutlined />
        }
      />
    </Tooltip>
  );
}
