import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_ALL_BEACONS,
  GET_SELECT_BEACON,
  GET_STORES_BEACON_DATA,
  GET_BEACON_DATA,
  RESET_STORE_BEACON_DATA,
  RESET_BEACON_DATA,
  GET_MULTIPLE_STORES_BEACON_DATA,
  GET_BRAND_BEACONS,
  GET_BEACON_HISTORY,
  RESET_BEACON_DATA_FIELD,
} from './type';
import BeaconsService from '@Services/Api/Beacons';
import {
  BeaconDetailFilterParams,
  BeaconFilterParams,
  GetBeaconParams,
  MultipleBeaconDetailFilterParams,
} from '@Types/Beacon';
import StoresService from '@Services/Api/Stores';
import BrandService from '@Services/Api/Brand';
import BeaconRulesService from '@Services/Api/BeaconRulesService';

export const getAllBeacons = createAsyncThunk(
  GET_ALL_BEACONS,
  async (params: GetBeaconParams) => {
    try {
      const allBeacon = await new BeaconsService().GetAllBeacons(
        params || { archived: false }
      );
      return allBeacon;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
export const getBeaconById = createAsyncThunk(
  GET_SELECT_BEACON,
  async (beaconId: number) => {
    try {
      const version = await new BeaconsService().GetBeaconById(beaconId);
      return version;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getStoreBeaconsData = createAsyncThunk(
  GET_STORES_BEACON_DATA,
  async (params: BeaconFilterParams) => {
    try {
      const storeBeaconsData = await new StoresService().GetStoresBeaconsData(
        params
      );
      return storeBeaconsData;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getMultipleStoreBeaconsData = createAsyncThunk(
  GET_MULTIPLE_STORES_BEACON_DATA,
  async (params: MultipleBeaconDetailFilterParams) => {
    try {
      const storeBeaconsData =
        await new BrandService().GetMultipleStoresBeaconsData(params);
      return storeBeaconsData;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getBeaconData = createAsyncThunk(
  GET_BEACON_DATA,
  async (params: BeaconDetailFilterParams) => {
    try {
      const beaconsData = await new BeaconsService().GetBeaconData(params);
      return beaconsData;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
export const resetStoreBeaconsData = createAction(RESET_STORE_BEACON_DATA);
export const resetBeaconData = createAction(RESET_BEACON_DATA);
export const resetBeaconDataField = createAction(RESET_BEACON_DATA_FIELD);

export const getBrandBeacons = createAsyncThunk(
  GET_BRAND_BEACONS,
  async (brandId: number) => {
    try {
      const beacons = await new BeaconRulesService().GetStoreBeacons(brandId);
      return beacons;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getBeaconHistory = createAsyncThunk(
  GET_BEACON_HISTORY,
  async (beaconId: number) => {
    try {
      return await new BeaconsService().GetBeaconHistory(beaconId);
    } catch (err) {
      console.error(err);
      return {};
    }
  }
);
