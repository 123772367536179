import FormCard from '@Components/FormCard';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import BeaconTypes from '@Types/Beacon';
import PageSkeletons from '@Components/PageSkeletons';
import BeaconForm from '@Forms/BeaconForm';
import { getBeaconById, resetBeaconDataField } from '@Store/Beacons/action';
import BeaconsService from '@Services/Api/Beacons';
import { apiCall } from '@Utils/index';
import { message } from 'antd';

type Props = {
  allEditForm?: boolean;
  beaconIds?: Array<number>;
  modalOnClose?: () => void;
  backOff?: boolean;
};

export default function BeaconEdit({
  allEditForm,
  beaconIds,
  modalOnClose,
  backOff,
}: Props) {
  const { beaconId } = useParams();
  const dispatch = useAppDispatch();
  const beaconData = useAppSelector(s => s.Beacons.beaconData);
  let navigate = useNavigate();

  useEffect(() => {
    if (beaconId) {
      dispatch(getBeaconById(parseInt(beaconId)));
    }

    return () => {
      dispatch(resetBeaconDataField());
    };
  }, [beaconId]);

  const handleSubmit = async (newBeacon: BeaconTypes.UpdateBeacon) => {
    if (!beaconId && !beaconIds) {
      throw message.error('Beacon ID must be provided!');
    } else if (beaconId && isNaN(parseInt(beaconId))) {
      throw message.error('Beacon ID must be a number!');
    }

    let editedBeaconData: BeaconTypes.UpdateBeacon = {
      Name: newBeacon.Name,
      Surname: newBeacon.Surname,
      UserId: newBeacon.UserId,
      BeaconIds:
        beaconIds && beaconIds?.length > 0 ? beaconIds : [parseInt(beaconId!)],
    };

    try {
      const req = () => new BeaconsService().EditBeacon(editedBeaconData);
      await apiCall(req, 'editBeacon');
      if (beaconIds && modalOnClose) {
        modalOnClose();
      }
      if (!backOff) {
        navigate(-1);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  if (Object.keys(beaconData).length === 0 || beaconData.status === 'Pending')
    return <PageSkeletons />;

  return (
    <FormCard titleIcon={<EditOutlined />} titleKey="editBeacon">
      <BeaconForm
        onFinish={handleSubmit}
        beaconData={beaconData.data}
        allEditForm={allEditForm}
      />
    </FormCard>
  );
}
