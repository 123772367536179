import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import FilledText from '@Components/FilledText';
import { Typography } from 'antd';
import moment from 'moment';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { AllBeacon, AllBeacons } from '@Types/Beacon';
import BeaconActionButtons from '@Cards/BeaconCard/BeaconActionButtons';
import useWindowSize from '@Hooks/useWindowSize';

const { Text } = Typography;

interface Params {
  allBeacons: AllBeacons;
  getBeacons: Function;
}

/**
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <AllBeacons/>.
 */
export default function useBeaconsColumns(
  props: Params
): ColumnsType<AllBeacon> {
  const { allBeacons, getBeacons } = props;

  const beaconsActionPerm = useCheckPermission(Permissions.ADMIN_BEACON_ACTION);
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const beaconsTableColumns: ColumnsType<AllBeacon> = useMemo(() => {
    if (allBeacons.status !== Status.success) return [];

    const columns: ColumnsType<AllBeacon> = [
      {
        title: t('ID'),
        dataIndex: 'Id',
      },
      {
        title: t('name'),
        dataIndex: 'Name',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('Surname'),
        dataIndex: 'Surname',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('username'),
        dataIndex: 'UserMail',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('entityIds'),
        dataIndex: 'EntityIds',
        render: (_: any, beacon: AllBeacon) => (
          <HierarchyItems
            brandId={beacon.BrandId}
            storeId={beacon.StoreId}
            brand={beacon.Brand}
            store={beacon.Store}
          />
        ),
        width: windowSize?.width && windowSize.width > 1360 ? 300 : 100,
      },
      {
        title: t('externalId'),
        dataIndex: 'ExternalId',
      },
      {
        title: t('BatteryUpdated'),
        dataIndex: 'BatteryUpdated',
        render: (_: any, receiver: AllBeacon) => (
          <FilledText
            text={
              receiver.BatteryUpdated
                ? receiver.Battery +
                  ' (' +
                  moment(receiver.BatteryUpdated).format('L') +
                  ' ' +
                  moment(receiver.BatteryUpdated).format('LT ') +
                  ')'
                : receiver.Battery + ' ( )'
            }
          />
        ),
      },
      {
        title: t('BtMacAdress'),
        dataIndex: 'BTMacAddress',
        render: (value: string) => <FilledText text={value} />,
      },
    ];

    if (beaconsActionPerm) {
      columns.push({
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, beacon: AllBeacon) => (
          <BeaconActionButtons beacon={beacon} getBeacon={getBeacons} />
        ),
      });
    }

    return columns;
  }, [allBeacons.status]);

  return beaconsTableColumns;
}
