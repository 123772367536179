import React from 'react';
import { getActiveBoardCount } from '@Utils/index';

interface Props {
  AtLeast3DaysPassiveBoardCount: number;
  BoardCount: number;
  NotInstalledBoardCount: number;
  PassiveBoardCount: number;
  OnCarrierCount: number;
  onClick: () => void;
}

export const StoreMarkerImage: React.FC<Props> = props => {
  const {
    AtLeast3DaysPassiveBoardCount,
    NotInstalledBoardCount,
    PassiveBoardCount,
    OnCarrierCount,
    ...restProps
  } = props;

  const offBoards = PassiveBoardCount;
  const notInstalledBoardCount = NotInstalledBoardCount;
  const atLeast3DaysPassiveBoardCount = AtLeast3DaysPassiveBoardCount;
  const onTheWay = OnCarrierCount;

  const onBoards = getActiveBoardCount(props);

  const statusArray = [
    onTheWay ? 'onTheWay' : '',
    offBoards ? 'off' : '',
    notInstalledBoardCount ? 'notInstalled' : '',
    atLeast3DaysPassiveBoardCount ? 'longPassive' : '',
    onBoards ? 'on' : '',
  ].filter(Boolean);

  return (
    <img
      {...restProps}
      crossOrigin=""
      alt="Status Marker"
      src={`/clusters/${statusArray.sort().join('-')}.png`}
      style={{ width: 24, height: 24 }}
    />
  );
};
