export enum CameraTypeEnums {
  // [Display(Name = "Fisheye")]
  Fisheye = 0,

  //[Display(Name = "Head Counter")]
  //HeadCounter = 1,

  // [Display(Name = "Flat")]
  Flat = 2,

  // [Display(Name = "Personnel Tracking")]
  Personnel = 3,
}

export enum CameraClosed {
  open = 0,
  closed = 1,
}

export enum InterfaceCameraTypeEnums {
  CSI = 0,
  USB = 1,
  IP = 2,
}
