import { Tooltip } from '@Components/Tooltip';
import { UserDataItemBase } from '@Types/User';
import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';

interface Props {
  items: UserDataItemBase[];
}

const DetailedRow = ({ items }: Props) => {
  if (items.length === 0) return <span>-</span>;
  if (items.length === 1) return <span>{items[0].Name}</span>;

  return (
    <Tooltip
      title={items.map(item => (
        <React.Fragment key={item.Id}>
          <span>{item.Name}</span>
          <br />
        </React.Fragment>
      ))}
    >
      <InfoCircleOutlined />
    </Tooltip>
  );
};

export default DetailedRow;
