import BrandDetail from '@Forms/BrandDetail';
import { EditOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect } from 'react';
import { getBrandById } from '@Store/Brand/action';
import { Spin } from 'antd';
import useBrandImage from '@Hooks/useBrandImage';
import BrandService from '@Services/Api/Brands';
import { BrandEditForm, EditedBrand } from '@Types/Brand';
import { apiCall } from '@Utils/index';
import useHomePagePhoto from '@Hooks/useHomePagePhoto';

const EditBrand = () => {
  const brand = useAppSelector(state => state.Brand.currentBrand.data);
  const udentifyUsers = useAppSelector(state => state.User.udentifyUsers.data);
  const { brandId } = useParams();

  const dispatch = useAppDispatch();
  const brandLogo = useBrandImage(parseInt(brandId!));
  const brandHomePagePhoto = useHomePagePhoto(parseInt(brandId!));
  let navigate = useNavigate();

  useEffect(() => {
    if (brandId) {
      dispatch(getBrandById(parseInt(brandId)));
    }
  }, [brandId]);

  const handleSubmit = async (brandForm: BrandEditForm) => {
    const editedBrand: EditedBrand = {
      Name: brandForm.Name,
      AccountManagerId: udentifyUsers.find(
        u => u.Email === brandForm.AccountManager
      )?.Id!,
      GroupId: brandForm.GroupId,
      AccountOperationManagerId: udentifyUsers.find(
        u => u.Email === brandForm.AccountOperationManager
      )?.Id!,
      InPOC: !!brandForm.InPOC,
    };

    try {
      await apiCall(
        () => editBrand(editedBrand),
        'editBrand',
        () => navigate(-1)
      );
    } catch (e) {
      console.warn(e);
    }
  };

  const editBrand = async (brandForm: EditedBrand) => {
    return await new BrandService().EditBrand(brandId!, brandForm);
  };

  if (!brand?.Name) return <Spin size="large" />;
  return (
    <FormCard
      titleIcon={<EditOutlined />}
      titleKey="editBrand"
      extraTitle={brand.Name}
      className="brand-detail-wrapper"
    >
      {brand?.Id && brandLogo && brandHomePagePhoto ? (
        <BrandDetail
          onFinish={handleSubmit}
          brand={brand}
          brandLogo={brandLogo}
          brandHomePagePhoto={brandHomePagePhoto}
        />
      ) : (
        <Spin />
      )}
    </FormCard>
  );
};

export default EditBrand;
