import CardGridWrapper from '@Components/CardGridWrapper';
import DataDisplayTable from '@Components/DataDisplayTable';
import { Permissions } from '@Enums/Permission';
import { CreateBrandGroupModal } from '@Features/brand-groups/modals';
import useBoolean from '@Hooks/useBoolean';
import useCheckPermission from '@Hooks/useCheckPermission';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { getBrandGroups } from '@Store/BrandGroup/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Card, Pagination } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useBrandGroupsColumns from './useBrandGroupsColumns';
import {
  BrandGroupCard,
  SortByFilterBrandGroups,
} from '@Features/brand-groups/components';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useDataDisplay from '@Hooks/useDataDisplay';
import GoBackButton from '@Components/GoBackButton';
import { getAllBrandsWithoutPagination } from '@Store/Brand/action';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { FilterOption } from '@Types/Pagination';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';

export function BrandGroupsPage() {
  const { t } = useTranslation();

  useSetHeaderTitle(t('groups'));

  const [currentDisplay, toggleDisplay] = useDataDisplay('brandGroups');
  const brandGroups = useAppSelector(state => state.BrandGroup.brandGroups);

  const dispatch = useAppDispatch();

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.NUMBER },
    { field: 'Name', label: t('name'), type: FilterType.TEXT },
  ];

  const pageConfigResult = useUserPageConfig(
    'brandGroups',
    {
      orderBy: 'asc',
      sortKey: 'Id',
    },
    { fields: filterOptions.map(o => o.field) }
  );

  const { pagination, refetch } = usePaginatedQuery({
    status: brandGroups.status,
    filterOptions,
    pageConfigResult,
    queryFunction: p => dispatch(getBrandGroups(p)),
  });

  useEffect(() => {
    dispatch(getAllBrandsWithoutPagination());
  }, [dispatch]);

  const brandGroupsTableColumns = useBrandGroupsColumns({
    brandGroups,
    onRefetch: refetch,
  });

  const boardActionPerm = useCheckPermission(Permissions.ADMIN_BRAND_ACTION);

  const createBrandGroupModalState = useBoolean();

  return (
    <Card>
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <GoBackButton />
          <SortByFilterBrandGroups
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />

          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            disabled={!boardActionPerm}
            onClick={createBrandGroupModalState.setTrue}
            style={{ marginLeft: 'auto' }}
          >
            {t('createGroup')}
          </Button>
          {createBrandGroupModalState.value && (
            <CreateBrandGroupModal
              modalState={createBrandGroupModalState}
              onRefetch={refetch}
            />
          )}
        </div>

        <CardGridWrapper
          status={brandGroups.status}
          noData={brandGroups.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {brandGroups.data?.Data?.map(brandGroup => (
                  <BrandGroupCard
                    key={brandGroup.Id}
                    brandGroup={brandGroup}
                    onRefetch={refetch}
                  />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={brandGroupsTableColumns}
                dataSource={brandGroups.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={brandGroups.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={brandGroups.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}
