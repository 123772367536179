import { Button, Modal } from 'antd';
import { ReactElement } from 'react';
import { UndoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import createConfirmModal from '@Components/InfoModal';
import { Tooltip } from '@Components/Tooltip';

type Props = {
  onConfirm: () => void;
  skipConfirmation?: boolean;
};

function SubscribeButton({ onConfirm, skipConfirmation }: Props): ReactElement {
  const { t } = useTranslation();

  const onModalConfirm = async () => {
    try {
      onConfirm();
      Modal.destroyAll();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubscribe = () => {
    if (skipConfirmation) {
      onConfirm();
      return;
    }

    createConfirmModal(t('doYouAccept'), onModalConfirm);
  };

  return (
    <Tooltip title={t('renewSubscription')}>
      <Button
        shape="circle"
        icon={<UndoOutlined />}
        onClick={handleSubscribe}
      />
    </Tooltip>
  );
}

export default SubscribeButton;
