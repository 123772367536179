import UserService from '@Services/Api/User';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { setUserDetails } from '@Store/User/action';
import { IUserConfig } from '@Types/User';

type UpdateHomepageBrandConfigPayload = {
  homepageBrandSortBy?: 'name' | 'id';
  homepageBrandOrderBy?: 'asc' | 'desc';
};

export function useUpdateUserConfig() {
  const userConfig = useAppSelector(
    s => s.User.currentUserDetails.data.PanelConfig
  );

  const dispatch = useAppDispatch();

  const parsedConfig = userConfig
    ? (JSON.parse(userConfig ?? '{}') as IUserConfig)
    : null;

  async function update(payload: UpdateHomepageBrandConfigPayload) {
    const newUserConfig = {
      ...parsedConfig,
      adminPanel: {
        ...parsedConfig?.adminPanel,
        ...payload,
      },
    } as IUserConfig;

    await new UserService().SetPanelConfig(JSON.stringify(newUserConfig));
    const user = await new UserService().GetDetailsV2();
    dispatch(setUserDetails(user));
  }

  return {
    update,
    sortBy: parsedConfig?.adminPanel?.homepageBrandSortBy ?? 'id',
    orderBy: parsedConfig?.adminPanel?.homepageBrandOrderBy ?? 'desc',
  };
}
