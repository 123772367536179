import { useTranslation } from 'react-i18next';
import {
  getAllArchivedStores,
  getAllUnarchivedStores,
} from '@Store/Store/action';
import StoresDetail from '@Pages/Stores/Components/StoresDetail';

const AllStores = () => {
  const { t } = useTranslation();

  return (
    <StoresDetail
      getUnarchivedStoresAction={getAllUnarchivedStores}
      getArchivedStoresAction={getAllArchivedStores}
      title={t('stores')}
    />
  );
};

export default AllStores;
