import { PlusCircleOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import BeaconForm from '@Forms/BeaconForm';
import BeaconTypes from '@Types/Beacon';
import BeaconsService from '@Services/Api/Beacons';
import { apiCall } from '@Utils';
import { useNavigate } from 'react-router-dom';

const NewBeacon = () => {
  let navigate = useNavigate();

  const handleSubmit = async (newBeacon: BeaconTypes.NewBeacon) => {
    let newBeaconData: BeaconTypes.NewBeacon = {
      Name: newBeacon.Name,
      BtMacAddress: newBeacon.BtMacAddress,
      ExternalId: newBeacon.ExternalId,
      StoreIds: newBeacon.StoreIds,
      Surname: newBeacon.Surname,
      UserId: newBeacon.UserId,
    };
    try {
      await apiCall(() => addNewBeacon(newBeaconData), 'addBeacon');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addNewBeacon = async (newBeacon: BeaconTypes.NewBeacon) => {
    return await new BeaconsService().New(newBeacon);
  };

  return (
    <FormCard titleIcon={<PlusCircleOutlined />} titleKey="addBeacon">
      <BeaconForm onFinish={handleSubmit} />
    </FormCard>
  );
};

export default NewBeacon;
