import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { useTranslation } from 'react-i18next';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import { AllBrands, FetchedBrand } from '@Types/Brand';
import BrandActionButtons from '@Cards/BrandCard/BrandActionButtons';
import CardInfo from '@Components/Card/CardInfo';
import { InfoOutlined } from '@ant-design/icons';
import { BoardCountInfo } from '@Components/BoardCountInfo';
import { Tag } from 'antd';

interface Params {
  brands: AllBrands;
}

/**
 *
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <AllBrands/>.
 * @returns
 */
export default function useBrandsColumns({
  brands,
}: Params): ColumnsType<FetchedBrand> {
  const brandActionPerm = useCheckPermission(Permissions.ADMIN_BRAND_ACTION);
  const { t } = useTranslation();

  const brandsTableColumns: ColumnsType<FetchedBrand> = useMemo(() => {
    if (brands.status !== Status.success) return [];

    const columns: ColumnsType<FetchedBrand> = [
      {
        title: t('name'),
        dataIndex: 'Name',
      },
      {
        title: t('notifications'),
        dataIndex: 'nonuploadedPhotos',
        render: (_: any, brand: FetchedBrand) =>
          brand.HasDismissPhoto && (
            <CardInfo
              title={t('unloadedPhotoExists')}
              icon={<InfoOutlined />}
            />
          ),
      },
      {
        title: t('entityIds'),
        dataIndex: 'entityIds',
        render: (_: any, brand: FetchedBrand) => (
          <HierarchyItems brandId={brand.Id} brand={brand.Name} />
        ),
      },
      {
        title: t('passiveBoards'),
        dataIndex: 'passiveBoards',
        render: (_: any, brand: FetchedBrand) => <BoardCountInfo {...brand} />,
      },
      {
        title: t('isPoc?'),
        dataIndex: 'IsPOC',
        align: 'center',
        render: (_: any, brand: FetchedBrand) =>
          brand.InPOC ? <Tag color="geekblue">POC</Tag> : '-',
      },
    ];

    if (brandActionPerm) {
      columns.push({
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, brand: FetchedBrand) => (
          <BrandActionButtons brand={brand} />
        ),
      });
    }

    return columns;
  }, [brands.status]);

  return brandsTableColumns;
}
