import { EllipsisOutlined } from '@ant-design/icons';
import { DeleteSelectedsButton } from '@Components/DeleteSelectedsButton';
import DeletePopconfirm from '@Components/DeletePopconfirm';
import { Button, ButtonProps, Space } from 'antd';
import { EditSelectedsButton } from '@Components/EditSelectedsButton';
import { Tooltip } from '@Components/Tooltip';

type MultipleEntityActionsButtonProps = ButtonProps & {
  onDelete?: () => void;
  editGroup?: boolean;
  onEdit?: () => void;
};

export function MultipleEntityActionsButton({
  onDelete,
  editGroup,
  onEdit,
  ...restProps
}: MultipleEntityActionsButtonProps) {
  return (
    <Tooltip
      title={
        <Space direction="vertical">
          {editGroup ? (
            <EditSelectedsButton hidden={!onEdit} onClick={onEdit} />
          ) : (
            <DeletePopconfirm onConfirm={onDelete}>
              <DeleteSelectedsButton hidden={!onDelete} />
            </DeletePopconfirm>
          )}
        </Space>
      }
    >
      <Button icon={<EllipsisOutlined />} {...restProps} />
    </Tooltip>
  );
}
