import { Tooltip } from '@Components/Tooltip';
import { Button, ButtonProps } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

export type ActionButtonProps = {
  icon: React.ReactElement;
  onClick: React.MouseEventHandler<HTMLElement>;
  tooltip: string;
  tooltipDirection?: TooltipPlacement;
  className?: string;
  initiallyVisible?: boolean;
  hasPermission?: boolean;
  buttonProps?: ButtonProps;
};

const ActionButton = ({
  icon,
  onClick,
  tooltip,
  tooltipDirection,
  className,
  initiallyVisible,
  buttonProps,
}: ActionButtonProps) => {
  return (
    <Tooltip
      defaultOpen={initiallyVisible}
      placement={tooltipDirection}
      title={tooltip}
      className={'action-button' + (className || '')}
    >
      <Button shape="circle" icon={icon} onClick={onClick} {...buttonProps} />
    </Tooltip>
  );
};

export default ActionButton;
