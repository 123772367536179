import { AllGateway } from '@Types/Gateway';
import Card from '@Components/Card';
import { useTranslation } from 'react-i18next';
import GatewayActionButtons from './GatewayActionButtons';
import { Typography } from 'antd';
import { Tooltip } from '@Components/Tooltip';

const { Title } = Typography;
type Props = {
  gateway: AllGateway;
  getGateways: () => void;
};

export default function GatewayCard(props: Props) {
  const { gateway } = props;
  const { t } = useTranslation();

  return (
    <Card
      subHeader={true}
      content={{
        firsItem: {
          custom: (
            <Tooltip title={`${gateway.Brand}/${gateway.Store}(${gateway.Id})`}>
              <Title level={5} className="first-item-text">
                {`${gateway.Brand}/${gateway.Store}(${gateway.Id})`}
              </Title>
            </Tooltip>
          ),
        },
        secondItem: {
          text: gateway.ExternalId,
        },
        subItem: {
          text: t('gatewaySubItemTitle', {
            date: gateway.LastOnlineDate
              ? new Date(gateway.LastOnlineDate)
              : '-',
          }),
        },
      }}
      details={[
        {
          key: { text: t('isSetup.question') },
          value: {
            text: gateway.Setup ? t('isSetup.true') : t('isSetup.false'),
          },
        },
        {
          key: { text: t('createdDate') },
          value: {
            text: gateway.CreatedDate,
          },
        },
      ]}
      extraButtons={<GatewayActionButtons {...props} />}
    />
  );
}
