import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import '../style.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from '@Components/Tooltip';

type Props = {
  to: string;
};

function EditButton({ to }: Props) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('edit')}>
      <Link to={to}>
        <Button shape="circle" icon={<EditOutlined />} size="small" />
      </Link>
    </Tooltip>
  );
}

export default EditButton;
