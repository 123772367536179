import BoardCard from '@Cards/BoardCard';
import { FirstItemText } from '@Components/Card/FirstItemText';
import CardGridWrapper from '@Components/CardGridWrapper';
import { useHomepageContext } from '@Features/homepage/hooks';
import { useAppSelector } from '@Store/hooks';
import { Input } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { BoardActionButtonEnums } from '@Cards/BoardCard/BoardActionButtons';

interface IProps {
  showArchived: boolean;
}

export function BoardsList({ showArchived }: IProps) {
  const boards = useAppSelector(s => s.Board.fetchedBoards);

  const {
    storeId,
    boardSearchValue,
    setBoardSearchValue,
    getBoards,
    getStores,
    getBrands,
    showArchivedStores,
  } = useHomepageContext();

  const { t } = useTranslation();

  useEffect(() => {
    if (storeId) {
      getBoards(showArchived);
    }
  }, [storeId, showArchived]);

  function searchChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setBoardSearchValue(e.target.value);
  }

  const filteredBoards = boards?.data?.Data?.filter(
    board =>
      board.Id.toString().includes(boardSearchValue) ||
      board.Name.toLocaleLowerCase().includes(
        boardSearchValue.toLocaleLowerCase()
      )
  );

  return (
    <div className="homepage-entity-selector-container board-list">
      <Input
        onChange={searchChangeHandler}
        value={boardSearchValue}
        placeholder={t('searchBoardNameAndIdPlaceholder')}
      />
      <CardGridWrapper
        cards={filteredBoards?.map(board => (
          <BoardCard
            key={board.Id}
            board={board}
            getBoards={() => {
              getBrands();
              getStores(showArchivedStores.value);
              getBoards(showArchived);
            }}
            isArchived={showArchived}
            firstItem={<FirstItemText text={board.Name + ' - ' + board.Id} />}
            hiddenButtons={[
              BoardActionButtonEnums.CAMERAS,
              BoardActionButtonEnums.DELETE,
            ]}
          />
        ))}
        noData={filteredBoards?.length === 0}
        status={boards.status}
        error={boards.ErrorMessage}
        containerClassName="homepage-entity-selector"
      />
    </div>
  );
}
