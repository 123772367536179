import Card from '@Components/Card';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import { Typography } from 'antd';
import { UserFetchResponse } from '@Types/User';
import { useMemo } from 'react';
import UserActionButtons from './UserActionButtons';
import { useTranslation } from 'react-i18next';
import FilledText from '@Components/FilledText';
import DetailedRow from '@Components/Card/DetailedRow';
import './style.scss';
import { Tooltip } from '@Components/Tooltip';

const { Title } = Typography;

interface Props {
  user: UserFetchResponse;
  getUsers: () => void;
}

export default function UserCard(props: Props) {
  const { user } = props;
  const userActionPerm = useCheckPermission(Permissions.ADMIN_USERS_ACTION);
  const { t } = useTranslation();

  const fullname = useMemo(
    () =>
      user.Name === user.Surname ? user.Name : user.Name + ' ' + user.Surname,
    [user.Name, user.Surname]
  );
  return (
    <Card
      className="user-card"
      key={user.Id}
      hideActionButtons={!userActionPerm}
      content={{
        firsItem: {
          custom: (
            <Tooltip title={fullname}>
              <Title level={5} className="name-text">
                {fullname}
              </Title>
            </Tooltip>
          ),
        },
        secondItem: { text: user.RoleName },
      }}
      details={[
        {
          key: { text: t('email') },
          value: {
            text: (
              <Tooltip title={user.Email}>
                <FilledText text={user.Email} />{' '}
              </Tooltip>
            ),
          },
        },
        {
          key: { text: t('createdDate') },
          value: { text: <FilledText text={user.CreatedDate} /> },
        },
        {
          key: { text: t('brands') },
          value: { text: <DetailedRow items={user.Brands} /> },
        },
        {
          key: { text: t('stores') },
          value: { text: <DetailedRow items={user.Stores} /> },
        },
        {
          key: { text: t('cameras') },
          value: { text: <DetailedRow items={user.Cameras} /> },
        },
      ]}
      extraButtons={<UserActionButtons {...props} />}
    />
  );
}
