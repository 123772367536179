import {
  PageIndex,
  PageSize,
  FilterBy,
  UsePaginationParams,
  UsePaginationResult,
  SortBy,
} from '@Types/Pagination';
import { useMemo, useState } from 'react';
import { defaultPaginationParams } from './constants';
import { usePageParamsQuery } from '@Hooks/usePageParamsQuery';
import { OrderBy } from '@Enums/Filter';

export function usePagination(
  params?: UsePaginationParams
): UsePaginationResult {
  const urlQuery = usePageParamsQuery();

  const [pageIndex, setPageIndex] = useState<PageIndex>(findDefaultPageIndex());
  const [pageSize, setPageSize] = useState<PageSize>(findDefaultPageSize());
  const [sortBy, setSortBy] = useState<SortBy>(findDefaultSortBy());
  const [orderBy, setOrderBy] = useState<OrderBy>(findDefaultOrderBy());
  const [filterBy, setFilterBy] = useState<FilterBy>(findDefaultFilterBy());

  function findDefaultPageIndex() {
    const pageIndexParam = urlQuery.Params('pageIndex');

    if (pageIndexParam) {
      const _pageIndex = parseInt(pageIndexParam);
      return isNaN(_pageIndex) ? defaultPaginationParams.PageIndex : _pageIndex;
    }

    return defaultPaginationParams.PageIndex;
  }

  function findDefaultPageSize() {
    const pageSizeParam = urlQuery.Params('pageSize');

    if (pageSizeParam) {
      const _pageSize = parseInt(pageSizeParam);
      return isNaN(_pageSize) ? defaultPaginationParams.PageSize : _pageSize;
    }

    return defaultPaginationParams.PageSize;
  }

  function findDefaultSortBy() {
    const sortByParam = urlQuery.Params('sortBy');

    if (!sortByParam) {
      return params?.pageConfigResult?.pageConfig.sortKey || 'Id';
    }

    return sortByParam;
  }

  function findDefaultOrderBy() {
    const orderByParam = urlQuery.Params('orderBy');

    if (!orderByParam) {
      if (!params?.pageConfigResult?.pageConfig.orderBy) {
        return OrderBy.ASC;
      }

      return params?.pageConfigResult?.pageConfig.orderBy === 'asc'
        ? OrderBy.ASC
        : OrderBy.DESC;
    }

    return orderByParam as OrderBy;
  }

  function findDefaultFilterBy() {
    const filterByParam = urlQuery.Params('filter');

    if (filterByParam) {
      try {
        const parsedFilterByParam = JSON.parse(filterByParam);

        if (!Array.isArray(parsedFilterByParam)) {
          return defaultPaginationParams.FilterBy;
        }

        return parsedFilterByParam;
      } catch {
        return defaultPaginationParams.FilterBy;
      }
    }

    return (
      params?.pageConfigResult?.pageConfig.filters ??
      defaultPaginationParams.FilterBy
    );
  }

  function onPageIndexChange(newPageIndex: PageIndex) {
    urlQuery.set('pageIndex', newPageIndex.toString());
    setPageIndex(newPageIndex);
  }

  function onPageSizeChange(newPageSize: PageSize) {
    urlQuery.set('pageSize', newPageSize.toString());
    setPageSize(newPageSize);
  }

  function onSortByChange(newSortBy: SortBy) {
    urlQuery.set('sortBy', newSortBy);
    params?.pageConfigResult?.onPageConfigChange({
      sortKey: newSortBy,
    });
    setSortBy(newSortBy);
  }

  function onOrderByChange(newOrderBy: OrderBy) {
    urlQuery.set('orderBy', newOrderBy);
    params?.pageConfigResult?.onPageConfigChange({
      orderBy: newOrderBy === OrderBy.ASC ? 'asc' : 'desc',
    });
    setOrderBy(newOrderBy);
  }

  function onFilterByChange(newFilters: FilterBy) {
    urlQuery.set('filter', JSON.stringify(newFilters));
    params?.pageConfigResult?.onPageConfigChange({
      filters: newFilters.map(f => ({ field: f.field, value: undefined })),
    });
    setFilterBy(newFilters);

    // reset page index after filter change
    onPageIndexChange(1);
  }

  return useMemo(
    () => ({
      PageIndex: pageIndex,
      PageSize: pageSize,
      SortBy: sortBy,
      OrderBy: orderBy,
      FilterBy: filterBy,
      onPageIndexChange,
      onPageSizeChange,
      onSortByChange,
      onOrderByChange,
      onFilterByChange,
    }),
    [pageIndex, pageSize, sortBy, orderBy, filterBy]
  );
}
