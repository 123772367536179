import Card, { CardProps } from '@Components/Card';
import { FetchedBrand } from '@Types/Brand';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import BrandActionButtons, {
  BrandActionButtonEnums,
} from './BrandActionButtons';
import './style.scss';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { useTranslation } from 'react-i18next';
import { CardInfoProps } from '@Components/Card/CardInfo';
import { PhoneOutlined, InfoOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { BoardCountInfo } from '@Components/BoardCountInfo';
import { Tag } from 'antd';

type Props = Partial<CardProps> & {
  brand: FetchedBrand;
  firstItem?: React.ReactNode;
  secondItem?: React.ReactNode;
  isArchived?: boolean;
  hiddenButtons?: BrandActionButtonEnums[];
};

export default function BrandCard(props: Props) {
  const {
    brand,
    onClick,
    firstItem,
    secondItem,
    isArchived,
    isSelected,
    hiddenButtons,
  } = props;

  const { t } = useTranslation();
  const brandActionPerm = useCheckPermission(Permissions.ADMIN_BRAND_ACTION);
  const infoLabels = useMemo(() => {
    const results: CardInfoProps[] = [];
    if (brand.HasDismissPhoto) {
      results.push({ title: t('unloadedPhotoExists'), icon: <InfoOutlined /> });
    }
    if (brand.AccountManager) {
      results.push({
        showOnHoverOnly: true,
        title: (
          <div>
            <p>{t('csManager') + ': ' + brand.AccountManager}</p>
            <p>
              {t('operationManager') + ': ' + (brand.OperationManager ?? '-')}
            </p>
          </div>
        ),
        icon: <PhoneOutlined />,
      });
    }
    return results;
  }, [t, brand.HasDismissPhoto, brand.AccountManager, brand.OperationManager]);

  return (
    <div className="brand-card-container">
      <Card
        key={brand.Id}
        activeBorders={isSelected}
        disableDetail
        className="brand-card"
        hideActionButtons={!brandActionPerm}
        cardInfoItems={infoLabels}
        content={{
          firsItem: {
            custom: firstItem ?? (
              <HierarchyItems
                brandId={brand.Id}
                brand={brand.Name}
                textWidth="20ch"
              />
            ),
          },
          secondItem: {
            custom: secondItem ?? <BoardCountInfo {...brand} />,
          },
        }}
        extraButtons={
          <BrandActionButtons
            brand={brand}
            isArchived={isArchived}
            hiddenButtons={hiddenButtons}
          />
        }
        onClick={onClick}
        absoluteItem={
          brand.InPOC && (
            <Tag className="is-poc" color="geekblue">
              POC
            </Tag>
          )
        }
      />
    </div>
  );
}
