import Card from '@Components/Card';
import { Permissions } from '@Enums/Permission';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Role } from '@Types/Role';
import { Typography } from 'antd';
import { ReactElement } from 'react';
import RoleActionButtons from './RoleActionButtons';
import { Tooltip } from '@Components/Tooltip';

const { Title } = Typography;
interface Props {
  role: Role;
  getRoles: () => void;
}

function RoleCard(props: Props): ReactElement {
  const {
    role: { RoleId, RoleName },
  } = props;

  const rolesActionPerm = useCheckPermission(
    Permissions.ADMIN_CUSTOM_ROLE_ACTION
  );
  return (
    <Card
      key={RoleId}
      disableDetail
      className="role-card"
      hideActionButtons={!rolesActionPerm}
      content={{
        firsItem: {
          custom: (
            <Tooltip title={RoleName}>
              <Title level={5} className="first-item-text">
                {RoleName}
              </Title>
            </Tooltip>
          ),
        },
      }}
      extraButtons={<RoleActionButtons {...props} />}
    />
  );
}

export default RoleCard;
