import Card, { CardProps } from '@Components/Card';
import './style.scss';
import { BrandGroupActionButtons } from './BrandGroupActionButtons';
import { IBrandGroup } from '@Types/BrandGroup';
import { Typography } from 'antd';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import { useMemo } from 'react';
import { useAppSelector } from '@Store/hooks';
import Status from '@Enums/Status';
import { BoardCountInfo } from '@Components/BoardCountInfo';
import { Tooltip } from '@Components/Tooltip';
import { Link } from 'react-router-dom';

const { Title } = Typography;

interface Props extends Partial<CardProps> {
  brandGroup: IBrandGroup;
  onRefetch: () => void;
}

export function BrandGroupCard(props: Props) {
  const { brandGroup, onRefetch, isSelected, ...restProps } = props;

  const { Id, Name } = brandGroup;

  const brands = useAppSelector(s => s.Brand.allBrands);

  const rolesActionPerm = useCheckPermission(Permissions.ADMIN_BRAND_ACTION);

  const boardCounts = useMemo(() => {
    if (brands.status !== Status.success) {
      return undefined;
    }

    const brandList =
      brands.data?.Data?.filter(brand => brand.GroupId === Id) ?? [];

    const boardCount = brandList.reduce<number>((sum, item) => {
      sum += item.BoardCount ?? 0;
      return sum;
    }, 0);

    const passiveBoardCount = brandList.reduce<number>((sum, item) => {
      sum += item.PassiveBoardCount ?? 0;
      return sum;
    }, 0);

    const atLeast3DaysPassiveBoardCount = brandList.reduce<number>(
      (sum, item) => {
        sum += item.AtLeast3DaysPassiveBoardCount ?? 0;
        return sum;
      },
      0
    );

    const notInstalledBoardCount = brandList.reduce<number>((sum, item) => {
      sum += item.NotInstalledBoardCount ?? 0;
      return sum;
    }, 0);

    const onCarrierBoardCount = brandList.reduce<number>((sum, item) => {
      sum += item.OnCarrierCount ?? 0;
      return sum;
    }, 0);

    if (boardCount !== undefined && passiveBoardCount !== undefined) {
      return {
        boardCount,
        passiveBoardCount,
        atLeast3DaysPassiveBoardCount,
        notInstalledBoardCount,
        onCarrierBoardCount,
      };
    }

    return undefined;
  }, [brands.status]);

  return (
    <Card
      activeBorders={isSelected}
      isDetailAlwaysOpen={false}
      disableDetail
      hideActionButtons={!rolesActionPerm}
      content={{
        firsItem: {
          custom: (
            <Link
              to={`/brands?filter=[{"field":"GroupId","value":[${Id}]}]&pageIndex=1`}
              onClick={e => {
                // user expects to go to link after clicking group name. this line prevents default card on click event.
                e.stopPropagation();
              }}
            >
              <Tooltip title={Name}>
                <Title level={5} className="first-item-text" underline>
                  {Name}
                </Title>
              </Tooltip>
            </Link>
          ),
        },
        secondItem: boardCounts
          ? {
              custom: (
                <BoardCountInfo
                  BoardCount={boardCounts.boardCount}
                  PassiveBoardCount={boardCounts.passiveBoardCount}
                  NotInstalledBoardCount={boardCounts.notInstalledBoardCount}
                  AtLeast3DaysPassiveBoardCount={
                    boardCounts.atLeast3DaysPassiveBoardCount
                  }
                  OnCarrierCount={boardCounts.onCarrierBoardCount}
                />
              ),
            }
          : undefined,
      }}
      extraButtons={<BrandGroupActionButtons id={Id} onRefetch={onRefetch} />}
      {...restProps}
    />
  );
}
