import {
  BarChartOutlined,
  CameraOutlined,
  ExperimentOutlined,
  FolderOpenOutlined,
  InboxOutlined,
  NodeIndexOutlined,
  NotificationOutlined,
  VideoCameraAddOutlined,
} from '@ant-design/icons';
import SSHTunnelModal from '@Cards/BoardCard/SSHTunnelModal';
import EditButton from '@Components/Card/EditButton';
import CardButton from '@Components/CardButton';
import { Popconfirm } from '@Components/Popconfirm';
import { Permissions } from '@Enums/Permission';
import PermissionCheck from '@HOC/PermissionCheck';
import useCheckPermission from '@Hooks/useCheckPermission';
import BoardsService from '@Services/Api/Boards';
import CamerasService from '@Services/Api/Cameras';
import { FetchedCamera } from '@Types/Camera';
import { apiCall } from '@Utils/index';
import { Space } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export enum CameraActionButtonEnums {
  ALL,
  NAVIGATE_TO_NOTIFICATION_RULES,
  OPEN_SSH,
  TAKE_SS,
  ARCHIVE,
  ADD_CAMERA_RECORD,
  NAVIGATE_TO_DATA,
  EDIT,
  ADD_TEST_PHOTO,
}

interface Props {
  camera: FetchedCamera;
  isArchived: boolean;
  getCameras: () => void;
  hiddenButtons?: CameraActionButtonEnums[];
}

export default function CameraActionButtons(props: Props) {
  const { camera, getCameras, isArchived, hiddenButtons } = props;
  const [showSSHTunnelModal, setShowSSHTunnelModal] = useState<boolean>(false);

  const hasCameraEditPermission = useCheckPermission(
    Permissions.ADMIN_CAMERA_EDIT
  );

  const openSSHModal = () => {
    setShowSSHTunnelModal(true);
  };

  const closeSSHModal = () => {
    setShowSSHTunnelModal(false);
  };

  const takeScreenshotHandler = async () => {
    try {
      await apiCall(takeScreenshot, 'takeScreenshot');
    } catch (e) {
      console.warn(e);
    }
  };

  const takeScreenshot = async () => {
    return await new BoardsService().TakeScreenshot(camera.BoardId, camera.Id);
  };

  const toggleArchiveType = async () => {
    try {
      if (isArchived) {
        await new CamerasService().UnarchiveCamera(camera.Id);
      } else {
        await new CamerasService().ArchiveCamera(camera.Id);
      }
      getCameras();
    } catch (e) {
      console.warn(e);
    }
  };

  const addTestPhoto = async () => {
    try {
      await new CamerasService().SetTestPhoto(camera.Id);
      getCameras();
    } catch (e) {
      console.warn(e);
    }
  };

  if (hiddenButtons?.includes(CameraActionButtonEnums.ALL)) {
    return <></>;
  }

  return (
    <>
      <Space direction="horizontal" size="small" wrap>
        {!hiddenButtons?.includes(
          CameraActionButtonEnums.NAVIGATE_TO_NOTIFICATION_RULES
        ) && (
          <Link
            to={`/notification-rules?filter=[{"field":"CameraId","value":${camera.Id}}]&pageIndex=1`}
          >
            <CardButton
              icon={<NotificationOutlined />}
              tooltipKey="notificationRules"
            />
          </Link>
        )}

        {!hiddenButtons?.includes(CameraActionButtonEnums.OPEN_SSH) && (
          <PermissionCheck permission={Permissions.ADMIN_CAMERA_OPEN_SSH}>
            <CardButton
              icon={<NodeIndexOutlined />}
              tooltipKey="openSSHTunnel"
              onClick={openSSHModal}
            />
          </PermissionCheck>
        )}

        {!hiddenButtons?.includes(CameraActionButtonEnums.TAKE_SS) && (
          <PermissionCheck permission={Permissions.ADMIN_CAMERA_TAKE_PHOTO}>
            <CardButton
              icon={<CameraOutlined />}
              tooltipKey="takeScreenshot"
              onClick={takeScreenshotHandler}
            />
          </PermissionCheck>
        )}

        {!hiddenButtons?.includes(CameraActionButtonEnums.ADD_TEST_PHOTO) && (
          <PermissionCheck permission={Permissions.ADMIN_CAMERA_EDIT}>
            <CardButton
              icon={<ExperimentOutlined />}
              tooltipKey="addTestPhoto"
              onClick={addTestPhoto}
            />
          </PermissionCheck>
        )}

        {!hiddenButtons?.includes(CameraActionButtonEnums.ARCHIVE) && (
          <PermissionCheck permission={Permissions.ADMIN_CAMERA_ARCHIVE}>
            <Popconfirm onConfirm={toggleArchiveType}>
              <CardButton
                icon={isArchived ? <InboxOutlined /> : <FolderOpenOutlined />}
                tooltipKey={isArchived ? 'unarchive' : 'archive'}
              />
            </Popconfirm>
          </PermissionCheck>
        )}

        {!hiddenButtons?.includes(
          CameraActionButtonEnums.ADD_CAMERA_RECORD
        ) && (
          <Link
            to={`/camera-records/new-camera-record/brandId=${camera.BrandId}&cameraId=${camera.Id}`}
          >
            <CardButton
              icon={<VideoCameraAddOutlined />}
              tooltipKey="addCameraRecord"
            />
          </Link>
        )}

        {!hiddenButtons?.includes(CameraActionButtonEnums.NAVIGATE_TO_DATA) && (
          <CardButton
            icon={<BarChartOutlined />}
            tooltipKey={'navigation.data'}
            to={`/all-cameras/${camera.Id}/data`}
          />
        )}

        {!hiddenButtons?.includes(CameraActionButtonEnums.EDIT) &&
          hasCameraEditPermission && (
            <EditButton
              to={`/brands/${camera.BrandId}/stores/${camera.StoreId}/boards/${camera.BoardId}/cameras/${camera.Id}`}
            />
          )}
      </Space>

      <SSHTunnelModal
        show={showSSHTunnelModal}
        boardId={camera.BoardId}
        onClose={closeSSHModal}
      />
    </>
  );
}
