import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_ALL,
  SET_SELECTED_BRAND,
  SET_SELECTED_BRANDS,
  GET_BRAND_IMAGE,
  GET_BRAND,
  SET_CURRENT_BRAND,
  GET_ALL_AVAILABLE,
  GET_API_KEY_LIST,
  GET_STORE_PRODUCTS,
  RESET_SELECTED_BRAND,
  GET_BRAND_HOMEPAGE_PHOTO,
  GET_ALL_WITHOUT_PAGINATION,
  GET_ALL_ARCHIVED_AND_UNARCHIVED,
  GET_BRANDS_REGIONS,
  GET_BRANDS_UNARCHIVE_CAMERAS,
} from './type';
import BrandsService from '../../Services/Api/Brands';
import BrandService from '../../Services/Api/Brand';
import {
  Brand,
  BrandImageResponse,
  BrandPhotoResponse,
  BrandUnArchiveCamerasTypes,
  GetAllAvailableBrandsParams,
  GetBrandsParams,
} from '@Types/Brand';
import AdminService from '@Services/Api/Admin';
import { PaginationParams } from '@Types/Filter';
import { Regions } from '@Types/Region';
import {
  defaultPaginationParams,
  noPaginationParams,
} from '@Hooks/usePagination.ts/constants';
import { createOrderBy } from '@Components/PaginationFilter/pagination-filter-utils';
import { OrderBy } from '@Enums/Filter';

export const getAllBrands = createAsyncThunk(
  GET_ALL,
  async (params?: GetBrandsParams) => {
    try {
      const allBrands = await new BrandsService().GetAll(
        params || { ...defaultPaginationParams, isArchived: false }
      );
      return allBrands;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getAllAvailableBrands = createAsyncThunk(
  GET_ALL_AVAILABLE,
  async (params?: GetAllAvailableBrandsParams | undefined) => {
    try {
      const allBrands = await new AdminService().GetAllAvailable(params);
      return allBrands;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getAllArchivedAndUnarchivedBrands = createAsyncThunk(
  GET_ALL_ARCHIVED_AND_UNARCHIVED,
  async () => {
    try {
      const pagination: PaginationParams = {
        ...noPaginationParams,
        OrderBy: createOrderBy('Name', OrderBy.ASC),
      };

      const [archivedBrands, unarchivedBrands] = await Promise.all(
        [true, false].map(isArchived =>
          new BrandsService().GetAll({
            ...pagination,
            isArchived,
          })
        )
      );

      return [
        ...unarchivedBrands.Data.map(d => ({ ...d, Archived: false })),
        ...archivedBrands.Data.map(d => ({ ...d, Archived: true })),
      ];
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getBrandById = createAsyncThunk(
  GET_BRAND,
  async (brandId: number) => {
    try {
      const brand = await new BrandsService().GetBrandById(brandId);
      return brand;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getBrandImage = createAsyncThunk(
  GET_BRAND_IMAGE,
  async (brandId: number) => {
    try {
      const brandImage = await new BrandService().GetBrandLogo(brandId);
      return {
        brandId: brandId.toString(),
        image: brandImage,
      } as BrandImageResponse;
    } catch (err) {
      console.error(err);
      return {
        brandId: null,
        image: '',
      } as BrandImageResponse;
    }
  }
);

export const getBrandHomePagePhoto = createAsyncThunk(
  GET_BRAND_HOMEPAGE_PHOTO,
  async (brandId: number) => {
    try {
      const brandImage = await new BrandService().GetBrandHomePage(brandId);
      return {
        brandId: brandId.toString(),
        photo: brandImage,
      } as BrandPhotoResponse;
    } catch (err) {
      console.error(err);
      return {
        brandId: null,
        photo: '',
      } as BrandPhotoResponse;
    }
  }
);

export const setCurrentBrand = createAction(
  SET_CURRENT_BRAND,
  (currentBrand: Brand) => {
    return {
      payload: currentBrand,
    };
  }
);

export const setSelectedBrand = createAction(
  SET_SELECTED_BRAND,
  (selectedBrand: Brand) => {
    return {
      payload: selectedBrand,
    };
  }
);

export const setSelectedBrands = createAction(
  SET_SELECTED_BRANDS,
  (selectedBrands: Brand[]) => {
    return {
      payload: selectedBrands,
    };
  }
);

export const getAllApiKeys = createAsyncThunk(
  GET_API_KEY_LIST,
  async (brandId: number) => {
    try {
      const apiKeys = await new BrandsService().GetAllApiKeys(brandId);
      return apiKeys;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getStoreProducts = createAsyncThunk(
  GET_STORE_PRODUCTS,
  async (brandId: number) => {
    try {
      const storeProducts = await new BrandsService().GetStoreProducts(brandId);
      return storeProducts;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const resetCurrentBrand = createAction(RESET_SELECTED_BRAND);

export const getAllBrandsWithoutPagination = createAsyncThunk(
  GET_ALL_WITHOUT_PAGINATION,
  async (params?: Partial<GetBrandsParams>) => {
    try {
      const allBrands = await new BrandsService().GetAll({
        ...noPaginationParams,
        isArchived: false,
        ...(params ?? {}),
      });
      return allBrands;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getBrandsRegions = createAsyncThunk(
  GET_BRANDS_REGIONS,
  async (brandIds: number[]) => {
    try {
      const allRegionsPromise = await Promise.all(
        brandIds.map(brandId => new BrandService().GetRegions(brandId))
      );

      const allRegion: Regions[] = [];
      for (const region of allRegionsPromise) {
        allRegion.push(...region);
      }
      return allRegion;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getUnArchiveBrandCameras = createAsyncThunk(
  GET_BRANDS_UNARCHIVE_CAMERAS,
  async (params: BrandUnArchiveCamerasTypes) => {
    try {
      const brandCameras = await new BrandService().UnArchiveBrandCameras(
        params
      );
      return brandCameras;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
