import ActionButton, { ActionButtonProps } from '@Components/ActionButton';
import { SyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

type Props = Omit<ActionButtonProps, 'icon' | 'tooltip'> & {};

export function RefreshButton(props: Props) {
  const { buttonProps = {}, ...restProps } = props;

  const { t } = useTranslation();

  return (
    <div>
      <ActionButton
        tooltip={t('refresh')}
        tooltipDirection="bottom"
        icon={<SyncOutlined />}
        buttonProps={{ shape: 'default', size: 'small', ...buttonProps }}
        {...restProps}
      />
    </div>
  );
}
