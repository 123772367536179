import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, ColProps, FormInstance, Input, Table, Tag } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import * as React from 'react';
import { useState } from 'react';
import { HardwareListFormItem } from '../HardwareListFormItem';
import { useTranslation } from 'react-i18next';
import FilledText from '@Components/FilledText';
import { InterfaceCameraTypeEnums } from '@Enums/Camera';
import './style.scss';
import DeleteButton from '@Components/Card/DeleteButton';

const { Column } = Table;

const filledTextColProps: ColProps = {
  style: {
    marginInline: 'auto',
  },
};

interface Props {
  readonly hardwares: FieldData[];
  readonly form: FormInstance;
  readonly remove: (index: number) => void;
  onDelete: (id: number) => void;
}

export const HardwareListTable: React.FC<Props> = props => {
  const { hardwares, form, remove, onDelete } = props;
  const [editingIndex, setEditingIndex] = useState<number | undefined>(
    undefined
  );
  const [isNewHardware, setNewHardware] = useState<boolean>(false);

  const { t } = useTranslation();

  const onSave = () => {
    form
      .validateFields()
      .then(() => {
        setNewHardware(false);
        setEditingIndex(undefined);
      })
      .catch(error => {
        console.log(error.errorFields);
      });
  };

  const onCancel = (index: number) => {
    if (isNewHardware) {
      remove(index);
    } else {
      form.resetFields([['hardwares', index, 'DefaultURL']]);
    }

    setNewHardware(false);
    setEditingIndex(undefined);
  };

  return (
    <Table
      className="hardware-list-table"
      dataSource={hardwares}
      pagination={false}
    >
      <Column
        align="center"
        dataIndex="Id"
        title={t('Id')}
        width={125}
        render={(_, __, index) => (
          <FilledText
            textColProps={filledTextColProps}
            text={form.getFieldValue(['hardwares', index, 'BoardBrandModelId'])}
          />
        )}
      />
      <Column
        align="center"
        dataIndex="Name"
        title={t('Name')}
        render={(_, __, index) => (
          <FilledText
            textColProps={filledTextColProps}
            text={form.getFieldValue(['hardwares', index, 'Name'])}
          />
        )}
      />
      <Column
        align="center"
        dataIndex="CameraInterfaceType"
        title={t('cameraType')}
        render={(_, __, index) => (
          <CameraTypeTag
            cameraType={form.getFieldValue([
              'hardwares',
              index,
              'CameraInterfaceType',
            ])}
          />
        )}
      />
      <Column
        width={300}
        align="center"
        dataIndex="DefaultURL"
        title={t('streamingUrl')}
        render={(_, __, index) => {
          return (
            <HardwareListFormItem
              name={[index, 'DefaultURL']}
              editing={index === editingIndex}
            >
              <Input allowClear placeholder={t('streamingUrl')} />
            </HardwareListFormItem>
          );
        }}
      />
      <Column
        width={150}
        align="center"
        title={t('actionButtons')}
        render={(_, __, index) => {
          if (index === editingIndex) {
            return (
              <>
                <Button
                  icon={<SaveOutlined />}
                  shape={'circle'}
                  type={'primary'}
                  style={{ marginRight: 8 }}
                  onClick={onSave}
                />
                <Button
                  icon={<CloseOutlined />}
                  shape={'circle'}
                  onClick={() => onCancel(index)}
                />
              </>
            );
          } else {
            return (
              <>
                <Button
                  icon={<EditOutlined />}
                  shape={'circle'}
                  style={{ marginRight: 8 }}
                  disabled={editingIndex !== undefined}
                  onClick={() => setEditingIndex(index)}
                />
                <DeleteButton
                  optionalMessage={t('deleteHardware?')}
                  size="middle"
                  onConfirm={() => {
                    onDelete(
                      form.getFieldValue([
                        'hardwares',
                        index,
                        'BoardBrandModelId',
                      ])
                    );
                  }}
                />
              </>
            );
          }
        }}
      />
    </Table>
  );
};

function CameraTypeTag(props: { cameraType: InterfaceCameraTypeEnums }) {
  const { cameraType } = props;
  const text = React.useMemo(() => {
    switch (cameraType) {
      case InterfaceCameraTypeEnums.CSI:
        return 'CSI';
      case InterfaceCameraTypeEnums.USB:
        return 'USB';
      case InterfaceCameraTypeEnums.IP:
        return 'IP';
      default:
        return 'Unknown';
    }
  }, [cameraType]);

  const color = React.useMemo(() => {
    switch (cameraType) {
      case InterfaceCameraTypeEnums.CSI:
        return 'magenta';
      case InterfaceCameraTypeEnums.USB:
        return 'volcano';
      case InterfaceCameraTypeEnums.IP:
        return 'green';
      default:
        return 'default';
    }
  }, [cameraType]);

  return <Tag color={color}>{text}</Tag>;
}
