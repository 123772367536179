import { createReducer } from '@reduxjs/toolkit';
import {
  AllBeacons,
  AllBeaconsFetchResponse,
  Beacon,
  AllBeacon,
  StoreBeaconsDataSerial,
} from '@Types/Beacon';
import { IStoreBeacon } from '@Types/BeaconRule';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import {
  getAllBeacons,
  getBeaconById,
  getStoreBeaconsData,
  getBeaconData,
  resetBeaconData,
  resetStoreBeaconsData,
  getMultipleStoreBeaconsData,
  getBrandBeacons,
  getBeaconHistory,
  resetBeaconDataField,
} from './action';

interface stateInterface {
  allBeacon: AllBeacons;
  beaconData: Beacon;
  storesBeaconData: asyncFetchInterface<StoreBeaconsDataSerial>;
  beaconDetail: asyncFetchInterface<StoreBeaconsDataSerial>;
  brandBeacons: asyncFetchInterface<IStoreBeacon[]>;
  beaconHistory: asyncFetchInterface<any>;
}

const initialState: stateInterface = {
  allBeacon: {
    data: {} as AllBeaconsFetchResponse,
    status: 'Empty',
  },
  beaconData: {
    data: {} as AllBeacon,
    status: 'Empty',
  },
  storesBeaconData: {
    data: [],
    status: 'Empty',
  },
  beaconDetail: {
    data: [],
    status: 'Empty',
  },
  brandBeacons: {
    data: [],
    status: 'Empty',
  },
  beaconHistory: {
    data: [],
    status: 'Empty',
  },
};
//
const BeaconReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAllBeacons.pending, state => {
      state.allBeacon = {
        ...state.allBeacon,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllBeacons.fulfilled, (state, action) => {
      state.allBeacon.data = action.payload as AllBeaconsFetchResponse;
      state.allBeacon.status = 'fulfilled';
    })
    .addCase(getAllBeacons.rejected, (state, action) => {
      state.allBeacon = {
        ...state.allBeacon,
        data: {} as AllBeaconsFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBrandBeacons.pending, state => {
      state.brandBeacons = {
        ...state.brandBeacons,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBrandBeacons.fulfilled, (state, action) => {
      state.brandBeacons.data = action.payload;
      state.brandBeacons.status = 'fulfilled';
    })
    .addCase(getBrandBeacons.rejected, (state, action) => {
      state.brandBeacons = {
        ...state.brandBeacons,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBeaconById.pending, state => {
      state.beaconData = {
        ...state.beaconData,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBeaconById.fulfilled, (state, action) => {
      state.beaconData.data = action.payload as AllBeacon;
      state.beaconData.status = 'fulfilled';
    })
    .addCase(getBeaconById.rejected, (state, action) => {
      state.beaconData = {
        ...state.beaconData,
        data: {} as AllBeacon,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getStoreBeaconsData.pending, state => {
      state.storesBeaconData = {
        ...state.storesBeaconData,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getStoreBeaconsData.fulfilled, (state, action) => {
      state.storesBeaconData.data = action.payload;
      state.storesBeaconData.status = 'fulfilled';
    })
    .addCase(getStoreBeaconsData.rejected, (state, action) => {
      state.storesBeaconData = {
        ...state.storesBeaconData,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getMultipleStoreBeaconsData.pending, state => {
      state.storesBeaconData = {
        ...state.storesBeaconData,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getMultipleStoreBeaconsData.fulfilled, (state, action) => {
      state.storesBeaconData.data = action.payload;
      state.storesBeaconData.status = 'fulfilled';
    })
    .addCase(getMultipleStoreBeaconsData.rejected, (state, action) => {
      state.storesBeaconData = {
        ...state.storesBeaconData,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBeaconData.pending, state => {
      state.beaconDetail = {
        ...state.beaconDetail,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBeaconData.fulfilled, (state, action) => {
      state.beaconDetail.data = action.payload;
      state.beaconDetail.status = 'fulfilled';
    })
    .addCase(getBeaconData.rejected, (state, action) => {
      state.beaconDetail = {
        ...state.beaconDetail,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(resetBeaconData, state => {
      state.beaconDetail = {
        ...state.beaconDetail,
        data: [],
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(resetBeaconDataField, state => {
      state.beaconData = {
        ...state.beaconData,
        data: {} as AllBeacon,
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(resetStoreBeaconsData, state => {
      state.storesBeaconData = {
        ...state.storesBeaconData,
        data: [],
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBeaconHistory.pending, state => {
      state.beaconHistory = {
        ...state.beaconHistory,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBeaconHistory.fulfilled, (state, action) => {
      state.beaconHistory.data = action.payload as any;
      state.beaconHistory.status = 'fulfilled';
    })
    .addCase(getBeaconHistory.rejected, (state, action) => {
      state.beaconHistory = {
        ...state.beaconHistory,
        data: {} as any,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default BeaconReducer;
