import TextFormatter from '@Components/TextFormatter';
import { Row, Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { Tooltip } from '@Components/Tooltip';
const { Text } = Typography;

export interface CardEditItemProps {
  icon: React.ReactNode;
  name: string;
  id: number | string;
  tooltip?: string;
  routeTo: string;
  hasPermission: boolean;
  textWidth?: string;
}

const CardEditItem = ({
  icon,
  name,
  id,
  tooltip,
  routeTo,
  hasPermission,
  textWidth,
}: CardEditItemProps) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const itemClickHandler = () => navigate(routeTo);

  return (
    <button onClick={itemClickHandler} disabled={!hasPermission}>
      <Tooltip title={tooltip && t(tooltip)} placement="bottom">
        <TextFormatter text={name} textWidth={textWidth} />
        <Tooltip title={name} placement="top">
          <Space
            size={4}
            direction="vertical"
            className={`card-edit-item ${hasPermission ? '' : 'no-permission'}`}
          >
            <Row align="middle" justify="center">
              {icon}
            </Row>
            <Row align="middle" justify="center">
              <Text className="card-id" italic copyable>
                {id}
              </Text>
            </Row>
          </Space>
        </Tooltip>
      </Tooltip>
    </button>
  );
};

export default CardEditItem;
