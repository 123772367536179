import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { useTranslation } from 'react-i18next';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import FilledText from '@Components/FilledText';
import CameraActionButtons from '@Cards/CameraCard/Components/CameraActionButtons';
import { AllFetchedCameras, FetchedCamera } from '@Types/Camera';
import { convertInterfaceCameraEnumToText } from '@Utils/Camera';
import { InterfaceCameraTypeEnums } from '@Enums/Camera';
import { convertApiDate } from '@Utils/index';
import { InfoCircleOutlined } from '@ant-design/icons';
import useWindowSize from '@Hooks/useWindowSize';
import StatusBadge from '@Components/StatusBadge';
import { Tooltip } from '@Components/Tooltip';

interface Params {
  cameras: AllFetchedCameras;
  isArchived: boolean;
  getCameras: () => void;
}

/**
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <CamerasDetail />.
 */
export default function useCamerasColumns(
  params: Params
): ColumnsType<FetchedCamera> {
  const { cameras } = params;
  const boardActionPerm = useCheckPermission(Permissions.ADMIN_BOARD_ACTION);
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const camerasTableColumns: ColumnsType<FetchedCamera> = useMemo(() => {
    if (cameras.status !== Status.success) return [];

    const columns: ColumnsType<FetchedCamera> = [
      {
        title: t('name'),
        dataIndex: 'Name',
      },
      {
        title: t('status'),
        dataIndex: 'Status',
        render: (_, board: FetchedCamera) => (
          <StatusBadge isOn={board.DataInLastHour} />
        ),
      },
      {
        title: t('entityIds'),
        dataIndex: 'entityIds',
        render: (_: any, camera: FetchedCamera) => (
          <HierarchyItems
            brandId={camera.BrandId}
            storeId={camera.StoreId}
            boardId={camera.BoardId}
            cameraId={camera.Id}
            brand={camera.Brand}
            store={camera.Store}
            board={camera.Board}
            camera={camera.Name}
          />
        ),
        width: windowSize?.width && windowSize.width > 1360 ? 400 : 210,
      },
      {
        title: t('lastDataTime'),
        dataIndex: 'LastDataTime',
        render: (value: string) => (
          <FilledText text={value ? convertApiDate(value, true) : '-'} />
        ),
      },
      {
        title: t('version'),
        dataIndex: 'Version',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('RTSP Url'),
        dataIndex: 'RTSPUrl',
        render: (RTSPUrl: string) =>
          RTSPUrl ? (
            <Tooltip title={RTSPUrl}>
              <InfoCircleOutlined />
            </Tooltip>
          ) : (
            '-'
          ),
      },
      {
        title: t('cameraType'),
        dataIndex: 'InterfaceType',
        render: (value: InterfaceCameraTypeEnums) => (
          <FilledText text={convertInterfaceCameraEnumToText(value)} />
        ),
      },
      {
        title: t('PhotoUploaded'),
        dataIndex: 'PhotoUploaded',
        render: (value: string) => <FilledText text={value} />,
      },
    ];

    if (boardActionPerm) {
      columns.push({
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, camera: FetchedCamera) => (
          <CameraActionButtons {...params} camera={camera} />
        ),
      });
    }

    return columns;
  }, [cameras.status, windowSize.width]);

  return camerasTableColumns;
}
