import {
  Button,
  Checkbox,
  Divider,
  Form,
  Image,
  Input,
  Select,
  Spin,
  Upload,
  message,
} from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ModalFooter from '@Components/ModalFooter';
import { useForm } from 'antd/lib/form/Form';
import { BrandImage } from '@Hooks/useBrandImage';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect, useState } from 'react';
import { getUdentifyUsers } from '@Store/User/action';
import { Brand } from '@Types/Brand';
import Text from 'antd/lib/typography/Text';
import { RcFile } from 'antd/lib/upload/interface';
import {
  getBrandById,
  getBrandHomePagePhoto,
  getBrandImage,
  setCurrentBrand,
} from '@Store/Brand/action';
import './style.scss';
import { apiCall, fileToBase64, getImageDimensions } from '@Utils/index';
import SearchableSelect from '@Components/SearchableSelect';
import BrandsService from '@Services/Api/Brands';
import BrandService from '@Services/Api/Brand';
import { BrandPhoto } from '@Hooks/useHomePagePhoto';
import { getBrandGroupsWithoutPagination } from '@Store/BrandGroup/action';

const LOGO_ASPECT_RATIO = 8 / 5;

type Props = {
  brand?: Brand;
  brandLogo?: BrandImage;
  brandHomePagePhoto?: BrandPhoto;
  onFinish: (values: any) => void;
};

export default function BrandDetail({
  onFinish,
  brand,
  brandLogo,
  brandHomePagePhoto,
}: Props) {
  const [newLogo, setNewLogo] = useState<RcFile>();
  const [newHomePagePhoto, setNewHomePagePhoto] = useState<RcFile>();
  const udentifyUsers = useAppSelector(s => s.User.udentifyUsers.data);
  const brandGroups = useAppSelector(s => s.BrandGroup.brandGroups.data?.Data);

  useEffect(() => {
    dispatch(getUdentifyUsers());
    dispatch(getBrandGroupsWithoutPagination());

    return () => {
      dispatch(setCurrentBrand({} as Brand));
    };
  }, []);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
  }, [brand?.Id, brandLogo?.status]);

  const onChangeLogo = async (file: RcFile) => {
    try {
      const imageAsBase64 = await fileToBase64(file);

      if (typeof imageAsBase64 !== 'string') {
        return;
      }

      const { width, height } = await getImageDimensions(imageAsBase64);

      const uploadedImageAspectRatio = width / height;

      if (uploadedImageAspectRatio !== LOGO_ASPECT_RATIO) {
        message.error(t('invalidBrandLogoError'));
        return false;
      }

      setNewLogo(file);
    } catch {
      setNewLogo(undefined);
    }

    return false;
  };

  const addBrandLogo = async () => {
    return await new BrandsService().AddBrandLogo(brand?.Id!, newLogo!);
  };

  const handleUploadBrandLogo = async () => {
    try {
      await apiCall(addBrandLogo, 'defaultProcess');
      refetchBrandData();
      setNewLogo(undefined);
    } catch (e) {
      console.warn(e);
    }
  };

  const onChangeHomePagePhoto = (file: RcFile) => {
    setNewHomePagePhoto(file);
    return false;
  };

  const addBrandHomePage = async () => {
    return await new BrandService().UploadBrandHomePage(
      brand?.Id!,
      newHomePagePhoto!
    );
  };

  const handleUploadBrandHomePagePhoto = async () => {
    try {
      await apiCall(addBrandHomePage, 'defaultProcess');
      refetchBrandData();
      setNewHomePagePhoto(undefined);
    } catch (e) {
      console.warn(e);
    }
  };

  const refetchBrandData = () => {
    dispatch(getBrandById(brand?.Id!));
    dispatch(getBrandImage(brand?.Id!));
    dispatch(getBrandHomePagePhoto(brand?.Id!));
  };

  const inputRule = [{ required: true, message: t('cannotBeEmpty') }];

  const groupOptions = brandGroups?.map(group => ({
    label: group.Name,
    value: group.Id,
  }));

  return (
    <>
      <Form
        form={form}
        name="brand-form"
        onFinish={onFinish}
        className="brand-detail-form-container"
        initialValues={brand}
      >
        <Form.Item label={t('brand')} name="Name" rules={inputRule}>
          <Input prefix={<HomeOutlined />} />
        </Form.Item>

        <Form.Item label={t('group')} name="GroupId">
          <Select options={groupOptions} allowClear showSearch />
        </Form.Item>

        <Form.Item
          label={t('csManager')}
          name="AccountManager"
          rules={inputRule}
        >
          <SearchableSelect placeholder={t('csManager')} showSearch>
            {udentifyUsers.map(user => (
              <Select.Option
                key={user.Id}
                value={user.Email}
                label={user.Email}
              >
                {user.Email}
              </Select.Option>
            ))}
          </SearchableSelect>
        </Form.Item>

        <Form.Item
          label={t('operationManager')}
          name="AccountOperationManager"
          rules={inputRule}
        >
          <SearchableSelect placeholder={t('operationManager')} showSearch>
            {udentifyUsers.map(user => (
              <Select.Option
                key={user.Id}
                value={user.Email}
                label={user.Email}
              >
                {user.Email}
              </Select.Option>
            ))}
          </SearchableSelect>
        </Form.Item>

        <Form.Item label={t('isPoc?')} name="InPOC" valuePropName="checked">
          <Checkbox />
        </Form.Item>
      </Form>

      {brandLogo && (
        <div className="brand-image-container">
          <div className="brand-image-row">
            <Text>{t('brandLogo')}:</Text>
            <>
              {brandLogo?.status === 'Pending' ? (
                <Spin />
              ) : (
                <Image
                  width={102}
                  src={brandLogo?.image || undefined}
                  placeholder={t('notFound')}
                />
              )}
            </>
          </div>
          <div className="upload-image-container">
            <Upload
              listType="picture-card"
              beforeUpload={onChangeLogo}
              maxCount={1}
            >
              {t('newLogo')}
            </Upload>
            <Button
              disabled={!newLogo}
              type="primary"
              onClick={handleUploadBrandLogo}
            >
              {t('uploadLogo')}
            </Button>
          </div>
        </div>
      )}

      {brandHomePagePhoto && (
        <div className="brand-image-container">
          <div className="brand-image-row">
            <Text>{t('brandHomePagePhoto')}:</Text>
            <>
              {brandHomePagePhoto?.status === 'Pending' ? (
                <Spin />
              ) : (
                <Image
                  width={102}
                  src={brandHomePagePhoto?.photo || undefined}
                  placeholder={t('notFound')}
                />
              )}
            </>
          </div>
          <div className="upload-image-container">
            <Upload
              listType="picture-card"
              beforeUpload={onChangeHomePagePhoto}
              maxCount={1}
            >
              {t('new')}
            </Upload>
            <Button
              disabled={!newHomePagePhoto}
              type="primary"
              onClick={handleUploadBrandHomePagePhoto}
            >
              {t('upload')}
            </Button>
          </div>
        </div>
      )}

      <Divider />
      <ModalFooter formKey="brand-form" />
    </>
  );
}
