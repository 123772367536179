import Card, { CardProps } from '@Components/Card';
import './style.scss';
import { Typography } from 'antd';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import { useTranslation } from 'react-i18next';
import { ITicket } from '@Types/ticket.types';
import { TicketStatusTag } from '../TicketStatusTag';
import { TicketType } from '@Enums/ticket.enums';
import { convertApiDate } from '@Utils/index';
import { TicketActionButtons } from '../TicketActionButtons';
import { useMemo } from 'react';
import { CardDetails } from '@Components/Card/types';
import { Tooltip } from '@Components/Tooltip';

const { Title } = Typography;

interface Props extends Partial<CardProps> {
  ticket: ITicket;
  onRefetch: () => void;
}

export function TicketCard(props: Props) {
  const { ticket, onRefetch, isSelected, ...restProps } = props;

  const { TicketStatus, CreatedDateTime, CreatedUserName } = ticket;

  const { t } = useTranslation();

  const rolesActionPerm = useCheckPermission(Permissions.ADMIN_BRAND_ACTION);

  const details = useMemo<CardDetails>(() => {
    const result = [
      {
        key: { text: t('status') },
        value: {
          text: <TicketStatusTag status={TicketStatus} />,
          isCustom: true,
        },
      },
      {
        key: { text: t('title') },
        value: {
          text: t(`ticketTypes.${TicketType[ticket.TicketType]}`),
        },
      },
    ];

    return result;
  }, [t, ticket, TicketStatus]);

  return (
    <Card
      activeBorders={isSelected}
      hideActionButtons={!rolesActionPerm}
      content={{
        firsItem: {
          custom: (
            <Tooltip title={CreatedUserName}>
              <Title level={5} className="first-item-text">
                {CreatedUserName}
              </Title>
            </Tooltip>
          ),
        },
        secondItem: {
          custom: (
            <Tooltip title={convertApiDate(CreatedDateTime)}>
              <Title level={5} className="first-item-text">
                {convertApiDate(CreatedDateTime)}
              </Title>
            </Tooltip>
          ),
        },
      }}
      extraButtons={
        <TicketActionButtons ticket={ticket} onRefetch={onRefetch} />
      }
      details={details}
      {...restProps}
    />
  );
}
