import { getActiveBoardCount } from '@Utils/index';
import { ClusterStatus, MarkerLabel } from './homepage.types';
import { ClusterIconStyle } from '@react-google-maps/marker-clusterer';

export function getClusterImagePath(statuses: ClusterStatus[]): string {
  const validStatuses = [
    'off',
    'notInstalled',
    'longPassive',
    'on',
    'onTheWay',
  ];
  const filteredStatuses = statuses.filter(status =>
    validStatuses.includes(status)
  );

  if (filteredStatuses.length === 0) {
    return '/clusters/empty.png';
  }

  // Sort statuses alphabetically to ensure consistent file naming
  const sortedStatuses = filteredStatuses.sort();
  return `/clusters/${sortedStatuses.join('-')}.png`;
}

/**
 * @see https://stackoverflow.com/a/37302915
 */
function getGoogleClusterInlineSvg(color: string) {
  var encoded = window.btoa(
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.5"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.3"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.1"/></g></defs><g fill="' +
      color +
      '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>'
  );

  return 'data:image/svg+xml;base64,' + encoded;
}

export function createClustererStyles(
  color: string,
  startSize = 55
): ClusterIconStyle[] {
  return Array(5)
    .fill(null)
    .map((_, i) => ({
      url: getGoogleClusterInlineSvg(color),
      height: startSize + i * 10,
      width: startSize + i * 10,
      textColor: '#171717',
      textSize: 12,
    }));
}

export function createClusterStatuses(markers: MarkerLabel[]) {
  const hasOff = markers.some(m => m.PassiveBoardCount);
  const hasOn = markers.some(m => getActiveBoardCount(m));
  const hasNotInstalled = markers.some(m => m.NotInstalledBoardCount);
  const hasLongPassive = markers.some(m => m.AtLeast3DaysPassiveBoardCount);
  const hasOnCarrier = markers.some(m => m.OnCarrierCount);

  const statuses: ClusterStatus[] = [];

  if (hasOff) {
    statuses.push('off');
  }

  if (hasOn) {
    statuses.push('on');
  }

  if (hasNotInstalled) {
    statuses.push('notInstalled');
  }

  if (hasLongPassive) {
    statuses.push('longPassive');
  }

  if (hasOnCarrier) {
    statuses.push('onTheWay');
  }

  return statuses;
}
