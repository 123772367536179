import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { PlusCircleFilled } from '@ant-design/icons';
import { Button, Card, Pagination, Spin } from 'antd';
import { Link } from 'react-router-dom';
import SortByFilter from './Components/SortByFilter';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect, useMemo } from 'react';
import Status from '@Enums/Status';
import CardGridWrapper from '@Components/CardGridWrapper';
import { getAllHardware, getBoardBrandCameras } from '@Store/Hardware/action';
import { HardwareListForm } from '@Features/hardware/components';
import { InterfaceCameraTypeEnums } from '@Enums/Camera';
import { enumToIterable } from '@Utils/helpers';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { FilterOption } from '@Types/Pagination';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';

const cameraTypes = enumToIterable(InterfaceCameraTypeEnums).map(
  (_, value) => value
);

function Hardware() {
  const { t } = useTranslation();
  const allHardware = useAppSelector(s => s.Hardware.allHardware);
  const boardBrandCameras = useAppSelector(s => s.Hardware.boardBrandCameras);

  const filterOptions: FilterOption[] = [
    { field: 'Name', label: t('name'), type: FilterType.TEXT },
  ];

  const pageConfigResult = useUserPageConfig(
    'hardware',
    {
      orderBy: 'asc',
      sortKey: 'Name',
    },
    { fields: filterOptions.map(o => o.field) }
  );

  const { pagination, refetch } = usePaginatedQuery({
    status: allHardware.status,
    filterOptions,
    pageConfigResult,
    queryFunction: p => dispatch(getAllHardware(p)),
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getBoardBrandCameras());
  }, []);

  const hardwareMap = useMemo(() => {
    return allHardware.data?.Data?.map(hardware => {
      return cameraTypes.map(cameraType => {
        const boardBrandCamera = boardBrandCameras.data?.find(
          p =>
            p.BoardBrandModelId === hardware.Id &&
            p.CameraInterfaceType === cameraType
        );

        return {
          Id: boardBrandCamera?.Id ?? 0,
          CameraInterfaceType:
            boardBrandCamera?.CameraInterfaceType === undefined
              ? cameraType
              : boardBrandCamera?.CameraInterfaceType,
          BoardBrandModelId: hardware.Id,
          Name: hardware.Name,
          DefaultURL: boardBrandCamera?.DefaultURL ?? '',
        };
      });
    }).flat();
  }, [
    allHardware.data?.Data,
    allHardware.status,
    boardBrandCameras.status,
    boardBrandCameras.data,
  ]);

  useSetHeaderTitle(t('hardwares'));

  return (
    <Card className="Region" loading={allHardware.status === Status.pending}>
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />

          <Link to="/hardware/new">
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              className="create-hardware-button"
            >
              {t('addHardware')}
            </Button>
          </Link>
        </div>
        <div className="card-grid-container">
          <CardGridWrapper
            status={allHardware.status}
            noData={allHardware.data?.Data?.length === 0}
            cards={
              !!(
                allHardware.status === Status.success &&
                boardBrandCameras.status === Status.success
              ) ? (
                <HardwareListForm
                  initialHardwares={hardwareMap}
                  getHardwares={refetch}
                />
              ) : (
                <Spin />
              )
            }
          />
        </div>
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={allHardware.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={allHardware.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}

export default Hardware;
