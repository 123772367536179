import { Button, Card, Modal, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import SortByFilter from './Components/SortByFilter';
import { getAllBeacons } from '@Store/Beacons/action';
import CardGridWrapper from '@Components/CardGridWrapper';
import React from 'react';
import BeaconCard from '@Cards/BeaconCard';
import DataDisplayTable from '@Components/DataDisplayTable';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useBeaconsColumns from './useBeaconsColumns';
import useDataDisplay from '@Hooks/useDataDisplay';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import ArchivedFilter from '@Components/ArchivedFilter';
import { SelectAllButtonGroup } from '@Components/SelectAllButtonGroup';
import { useMultipleRowSelector } from '@Hooks/useMultipleRowSelector';
import { addKeysToData } from '@Utils/index';
import BeaconEdit from './Pages/BeaconEdit';
import './style.scss';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { FilterOption } from '@Types/Pagination';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { useUserSelectList } from '@Hooks/api';
import Status from '@Enums/Status';

const Beacons = () => {
  const [currentDisplay, toggleDisplay] = useDataDisplay('beacons');
  const allBeacon = useAppSelector(s => s.Beacons.allBeacon);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showArchivedBeacons, setShowArchivedBeacons] =
    useState<boolean>(false);
  const [editBeaconsForm, setEditBeaconsForm] = useState(false);

  useSetHeaderTitle(
    `${t('navigation.beacons')} - ${
      showArchivedBeacons ? t('archivedBeacon') : t('unarchivedBeacon')
    }`
  );

  const allUsers = useUserSelectList();

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.NUMBER },
    { field: 'ExternalId', label: t('externalId'), type: FilterType.TEXT },
    { field: 'BTMacAddress', label: 'BT Mac Address', type: FilterType.TEXT },
    {
      field: 'Store.BrandId',
      label: t('brand') + ' ID',
      type: FilterType.NUMBER,
    },
    { field: 'Store.Brand.Name', label: t('brand'), type: FilterType.TEXT },
    { field: 'StoreId', label: t('store') + ' ID', type: FilterType.NUMBER },
    { field: 'Store.Name', label: t('store'), type: FilterType.TEXT },
    {
      field: 'UserId',
      label: t('users'),
      type: FilterType.TEXT_ARRAY_SELECT,
      loading: allUsers?.status === Status.pending,
      options: allUsers?.data?.map(u => ({
        label: u.Text,
        value: u.Value,
      })),
    },
  ];

  const pageConfigResult = useUserPageConfig(
    'beacons',
    {
      orderBy: 'asc',
      sortKey: 'Id',
    },
    { fields: filterOptions.map(o => o.field) }
  );

  const { pagination, refetch } = usePaginatedQuery({
    status: allBeacon.status,
    filterOptions,
    pageConfigResult,
    refetchDependencies: [showArchivedBeacons],
    queryFunction: p =>
      dispatch(
        getAllBeacons({
          ...p,
          archived: showArchivedBeacons,
        })
      ),
  });

  const toggleShowArchieveBeacons = () => {
    setShowArchivedBeacons(p => !p);
  };

  const {
    hasSelectedRow,
    rowSelection,
    selectedRowKeys,
    selectAll,
    onSelect,
    isSelected,
    reset,
  } = useMultipleRowSelector(allBeacon.data?.Data || []);

  async function handleEdit() {
    setEditBeaconsForm(true);
  }

  const editModalOnClose = () => {
    setEditBeaconsForm(false);
    refetch();
    reset();
  };

  const beaconsTableColumns = useBeaconsColumns({
    allBeacons: allBeacon,
    getBeacons: refetch,
  });

  return (
    <>
      <Card className="BeaconPage">
        <div className="grid-content-wrapper">
          <div className="filter-row">
            <SortByFilter
              onChange={pagination.onSortByChange}
              defaultValue={pagination.SortBy}
            />
            <PaginationOrderByFilter
              onChange={pagination.onOrderByChange}
              defaultValue={pagination.OrderBy}
            />
            <PaginationFilter
              filterOptions={filterOptions}
              pagination={pagination}
            />
            <DataDisplayToggler
              currentDisplay={currentDisplay}
              onToggle={toggleDisplay}
            />
            <ArchivedFilter
              onClick={toggleShowArchieveBeacons}
              showArchived={showArchivedBeacons}
            />

            <SelectAllButtonGroup
              onSelectAll={selectAll}
              onEdit={handleEdit}
              showActionButtons={hasSelectedRow}
              editGroup
            />

            <Link to="new-beacon">
              <Button type="primary" icon={<PlusCircleOutlined />}>
                {t('addBeacon')}
              </Button>
            </Link>
          </div>
          <div className="card-grid-container">
            <CardGridWrapper
              status={allBeacon.status}
              noData={allBeacon.data?.Data?.length === 0}
              cards={
                currentDisplay === 'card' ? (
                  <React.Fragment>
                    {allBeacon.data?.Data?.map(beacon => (
                      <BeaconCard
                        beacon={beacon}
                        isArchived={showArchivedBeacons}
                        getBeacon={refetch}
                        isSelected={isSelected(beacon.Id)}
                        onSelect={() => onSelect(beacon.Id)}
                      />
                    ))}
                  </React.Fragment>
                ) : (
                  <DataDisplayTable
                    columns={beaconsTableColumns}
                    dataSource={addKeysToData(allBeacon.data?.Data)}
                    rowSelection={rowSelection}
                  />
                )
              }
            />
          </div>
          <Pagination
            className="pagination"
            defaultCurrent={1}
            current={pagination.PageIndex}
            onChange={pagination.onPageIndexChange}
            pageSize={allBeacon.data?.PageSize || 20}
            showSizeChanger
            onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
            showLessItems={true}
            showTotal={total => `Total: ${total}`}
            total={allBeacon.data?.TotalNumberOfRecords}
          />
        </div>
      </Card>

      {editBeaconsForm && (
        <Modal
          open={editBeaconsForm}
          onCancel={() => setEditBeaconsForm(false)}
          okButtonProps={{ disabled: true }}
          footer={false}
        >
          <BeaconEdit
            allEditForm
            beaconIds={selectedRowKeys as number[]}
            modalOnClose={editModalOnClose}
            backOff={true}
          />
        </Modal>
      )}
    </>
  );
};

export default Beacons;
