import { Button } from 'antd';
import { ReactElement } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import '../style.scss';
import { useTranslation } from 'react-i18next';
import { ButtonSize } from 'antd/lib/button';
import { Popconfirm } from '@Components/Popconfirm';
import { Tooltip } from '@Components/Tooltip';

type Props = {
  onConfirm: () => void;
  skipConfirmation?: boolean;
  optionalMessage?: string;
  size?: ButtonSize;
};

function DeleteButton({
  onConfirm,
  skipConfirmation,
  optionalMessage,
  size,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('delete')}>
      <Popconfirm
        disabled={skipConfirmation}
        onConfirm={onConfirm}
        title={optionalMessage}
      >
        <Button
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={skipConfirmation ? onConfirm : () => {}}
          size={(size as ButtonSize) || 'small'}
        />
      </Popconfirm>
    </Tooltip>
  );
}

export default DeleteButton;
