import Card from '@Components/Card';
import BrandService from '@Services/Api/Brands';
import { getAllApiKeys } from '@Store/Brand/action';
import { useAppDispatch } from '@Store/hooks';
import { ApiKeyResponse } from '@Types/Brand';
import { Button } from 'antd';
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createMessage } from '@Utils/index';
import { Tooltip } from '@Components/Tooltip';

interface Props {
  apiKey: ApiKeyResponse;
}

const ApiKeyCard = ({ apiKey }: Props) => {
  const [isApiKeyCopied, setIsApiKeyCopied] = useState<boolean>(false);
  const [isPublicKeyCopied, setIsPublicKeyCopied] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const copyApiKey = () => {
    navigator.clipboard.writeText(apiKey.ApiKey);
    setIsApiKeyCopied(true);
    setIsPublicKeyCopied(false);

    createMessage('success', t('copiedToClipboard'), apiKey.ApiKey);
  };

  const copyPublicKey = () => {
    navigator.clipboard.writeText(apiKey.PublicKey);

    setIsApiKeyCopied(false);
    setIsPublicKeyCopied(true);

    createMessage('success', t('copiedToClipboard'), apiKey.PublicKey);
  };

  const deleteApiKey = async () => {
    await new BrandService().DeleteApiKey(apiKey.BrandId, apiKey.ApiKey);
    dispatch(getAllApiKeys(apiKey.BrandId));
  };

  return (
    <Card
      onClickDelete={deleteApiKey}
      content={{
        firsItem: { text: apiKey.Result },
        secondItem: { text: apiKey.ApiKey },
      }}
      details={[
        {
          key: { text: 'ApiKey' },
          value: {
            isCustom: true,
            text: (
              <Tooltip title={apiKey.ApiKey}>
                <Button onClick={copyApiKey}>
                  {isApiKeyCopied ? <CheckCircleOutlined /> : <CopyOutlined />}
                </Button>
              </Tooltip>
            ),
          },
        },
        {
          key: { text: 'PublicKey' },
          value: {
            isCustom: true,
            text: (
              <Tooltip title={apiKey.PublicKey}>
                <Button onClick={copyPublicKey}>
                  {isPublicKeyCopied ? (
                    <CheckCircleOutlined />
                  ) : (
                    <CopyOutlined />
                  )}
                </Button>
              </Tooltip>
            ),
          },
        },
      ]}
    />
  );
};

export default ApiKeyCard;
