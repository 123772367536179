import Card from '@Components/Card';
import { FetchedBoard } from '@Types/Board';
import { useTranslation } from 'react-i18next';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { Permissions } from '@Enums/Permission';
import useCheckPermission from '@Hooks/useCheckPermission';
import FilledText from '@Components/FilledText';
import BoardActionButtons, {
  BoardActionButtonEnums,
} from '@Cards/BoardCard/BoardActionButtons';
import { convertApiDate } from '@Utils/index';
import { BoardStatusInstalledIndicator } from '@Components/BoardStatusInstalledIndicator';
import { CardInfoProps } from '@Components/Card/CardInfo';
import { ExclamationOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import CardButton from '@Components/CardButton';
import { ActiveFileChangeDetailsModal } from '@Features/boards/modals';
import useBoolean from '@Hooks/useBoolean';
import { formatBoardIpText } from '@Features/boards/utils/board-utils';
import { Tooltip } from '@Components/Tooltip';

type Props = {
  board: FetchedBoard;
  isArchived: boolean;
  getBoards: () => void;
  firstItem?: React.ReactNode;
  hiddenButtons?: BoardActionButtonEnums[];
};

export default function BoardCard(props: Props) {
  const { board, firstItem, hiddenButtons, getBoards } = props;
  const { t } = useTranslation();

  const boardActionPerm = useCheckPermission(Permissions.ADMIN_BOARD_ACTION);

  const activeFileChangedModalState = useBoolean();

  const infoLabels = useMemo(() => {
    const results: CardInfoProps[] = [];

    if (board.ArchivedBy) {
      results.push({
        title: (
          <>
            <p>{`Arşivleyen:  ${board.ArchivedBy}`}</p>
            <p>{t('date') + ': ' + convertApiDate(board.ArchivedDate ?? '')}</p>
          </>
        ),
        icon: <FolderOpenOutlined />,
      });
    }

    if (board.IsActiveFileChanged && boardActionPerm) {
      results.push({
        className: 'active-file-changed-button-container',
        icon: (
          <>
            <CardButton
              icon={<ExclamationOutlined />}
              tooltipKey="newSoftwareVersionInfo"
              onClick={activeFileChangedModalState.setTrue}
              className="active-file-changed-button"
            />
            {activeFileChangedModalState.value && (
              <ActiveFileChangeDetailsModal
                modalState={activeFileChangedModalState}
                board={board}
                getBoards={getBoards}
              />
            )}
          </>
        ),
      });
    }

    return results;
  }, [
    t,
    board.ArchivedBy,
    board.ArchivedDate,
    boardActionPerm,
    activeFileChangedModalState.value,
  ]);

  const ipText = useMemo(
    () => formatBoardIpText(board),
    [board.IP, board.IsEthActive, board.IsWifiActive]
  );

  return (
    <Card
      key={board.Id}
      className="board-card"
      isDetailAlwaysOpen
      hideActionButtons={!boardActionPerm}
      cardInfoItems={infoLabels}
      content={{
        firsItem: {
          custom: firstItem ?? (
            <HierarchyItems
              brandId={board.BrandId}
              storeId={board.StoreId}
              boardId={board.Id}
              brand={board?.Brand}
              store={board.Store}
              board={board.Name}
            />
          ),
        },
      }}
      details={[
        {
          key: { text: <BoardStatusInstalledIndicator.Label /> },
          value: {
            text: (
              <BoardStatusInstalledIndicator
                board={board}
                onRefetch={getBoards}
              />
            ),
          },
        },
        {
          key: { text: t('codeVersion') },
          value: { text: <FilledText text={board.CodeVersion} /> },
        },
        {
          key: { text: 'IP' },
          value: {
            text: (
              <FilledText
                text={ipText}
                copyable={board.IP ? { text: board.IP } : undefined}
              />
            ),
          },
        },
        {
          key: { text: t('camera') },
          value: {
            text: board?.Cameras ? (
              <Tooltip title={board.Cameras.map(i => i.Name).join(' - ')}>
                {board.Cameras.map(cam => (
                  <FilledText text={cam.Name + ' '} />
                ))}
              </Tooltip>
            ) : (
              '-'
            ),
          },
        },
        {
          key: { text: 'Wifi Mac' },
          value: {
            text: (
              <FilledText text={board.WifiMac} copyable={!!board.WifiMac} />
            ),
          },
        },
        {
          key: { text: 'ETH Mac' },
          value: {
            text: <FilledText text={board.ETHMac} copyable={!!board.ETHMac} />,
          },
        },
        {
          key: { text: 'Bluetooth Mac' },
          value: {
            text: (
              <FilledText
                text={board.BluetoothMac}
                copyable={!!board.BluetoothMac}
              />
            ),
          },
        },

        {
          key: { text: 'Kernel Version' },
          value: {
            text: (
              <FilledText
                text={board.KernelVersion}
                copyable={!!board.KernelVersion}
              />
            ),
          },
        },
        {
          key: { text: t('lastStatusChangeTime') },
          value: { text: <FilledText text={board.LastStatusChangeTime} /> },
        },
      ]}
      extraButtons={
        <BoardActionButtons hiddenButtons={hiddenButtons} {...props} />
      }
    />
  );
}
