import { AllBeacon } from '@Types/Beacon';
import Card from '@Components/Card';
import moment from 'moment';
import BeaconActionButtons from './BeaconActionButtons';
import { Typography } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@Components/Tooltip';

const { Title } = Typography;
type Props = {
  beacon: AllBeacon;
  isArchived?: boolean;
  getBeacon: Function;
  isSelected: boolean;
  onSelect: (cameraRuleId: number) => void;
};

export default function BeaconCard({
  beacon,
  isArchived,
  getBeacon,
  isSelected,
  onSelect,
}: Props) {
  //harici id pil doluluk firma lokasyon
  const { t } = useTranslation();

  const details = [
    {
      key: { text: 'Name' },
      value: {
        text: beacon.Name + ' (' + beacon.Id + ')',
      },
    },
    {
      key: { text: 'Surname' },
      value: {
        text: beacon.Surname,
      },
    },
    {
      key: { text: t('username') },
      value: {
        text: beacon.UserMail ?? '-',
      },
    },
    {
      key: { text: 'BtMacAdress' },

      value: {
        text: beacon.BTMacAddress,
      },
    },
  ];

  useEffect(() => {
    if (isArchived)
      details.push({
        key: { text: t('ArchivingReason') },
        value: {
          text: t(beacon.ArchivingReason || ''),
        },
      });
  }, [isArchived]);
  return (
    <Card
      subHeader={true}
      isSelected={isSelected}
      isSelectable
      onSelect={() => onSelect(beacon.Id)}
      extraButtons={
        <BeaconActionButtons
          beacon={beacon}
          isArchived={isArchived}
          getBeacon={getBeacon}
        />
      }
      content={{
        firsItem: {
          custom: (
            <Tooltip
              title={`${beacon.Brand}/${beacon.Store}(${beacon.StoreId})`}
            >
              <Title level={5} className="first-item-text">
                {`${beacon.Brand}/${beacon.Store}(${beacon.StoreId})`}
              </Title>
            </Tooltip>
          ),
        },
        secondItem: {
          text: beacon.ExternalId,
        },
        subItem: {
          text: beacon.BatteryUpdated
            ? beacon.Battery +
              ' (' +
              moment(beacon.BatteryUpdated).format('L') +
              ' ' +
              moment(beacon.BatteryUpdated).format('LT ') +
              ')'
            : beacon.Battery + ' ( )',
        },
      }}
      details={details}
    />
  );
}
